import React from 'react'
import { styled } from '@mui/material'

const Wrapper = styled('div')`
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.1s;
`

const EditIcon = () => (
  <Wrapper>
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <g fill='#2E384D' fillRule='nonzero'>
          <path d='M3 21h3.75L17.81 9.94l-3.75-3.75L3 17.25V21zm2-2.92 9.06-9.06.92.92L5.92 19H5v-.92zM18.37 3.29a.996.996 0 0 0-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83a.996.996 0 0 0 0-1.41l-2.34-2.34z' />
        </g>
      </g>
    </svg>
  </Wrapper>
)

export default EditIcon
