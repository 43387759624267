import React from 'react'

const AddIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path fill='currentColor' fillRule='nonzero' d='M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6z' />
    </g>
  </svg>
)

export default AddIcon
