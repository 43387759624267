import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography, Button as MuiBtn } from '@mui/material'
import _ from 'lodash'

import {
  StyledCloseButton,
  StyledGridContainer,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalTitle
} from '../constants/styles.constant'
import ICONS from '../constants/icons.constant'
import Button from '../components/button/button.component'
import { useAppDispatch, useAppSelector } from '../hooks/redux.hook'
import { ButtonAppearance, Colors } from '../constants/appearance.constant'
import { CellType } from '../constants/global.constant'
import Table from '../components/table/table.component'
import AddCatalogSubscriptionsModal from '../components/addCatalogSubscriptionsModal/addCatalogSubscriptionsModal.component'
import Modal from '../components/modal/modal.component'
import { toggleModal } from '../state/features/ui.feature'
import {
  deleteFromAdminSelectedSubscriptionsCatalog,
  fetchMsAndSelectedCatalogs,
  setIsCatalogModalOpen
} from '../state/features/admin.feature'

const { EmptyCatalogIcon, AddIcon, DeleteIcon, CatalogModalWarningIcon } = ICONS

const columns = [
  { displayName: 'License title', key: 'name', cellType: CellType.text, width: '30%' },
  { displayName: 'License id', key: 'msSubscriptionId', cellType: CellType.text, width: '70%' }
]

const CatalogPage = () => {
  const dispatch = useAppDispatch()

  const [selected, setSelected] = useState<number[]>([])

  const { adminAllSubscriptionsCatalog, adminSelectedSubscriptionsCatalog, isCatalogModalOpen, loading, error } =
    useAppSelector((state) => state.admin)

  const { isModalOpen } = useAppSelector((state) => state.ui)

  useEffect(() => {
    dispatch(fetchMsAndSelectedCatalogs())
  }, [])

  useEffect(() => {
    setSelected([])
  }, [adminSelectedSubscriptionsCatalog])

  const selectedSubscriptionsCatalogWithExpiration = _.map(adminSelectedSubscriptionsCatalog, (itemSelectedCatalog) => {
    const isMsCatalogIncludedItem = Boolean(
      _.find(adminAllSubscriptionsCatalog, (msCatalogItem) => msCatalogItem.id === itemSelectedCatalog.msSubscriptionId)
    )
    return {
      ...itemSelectedCatalog,
      isExpired: !isMsCatalogIncludedItem
    }
  })

  const handleSelect = (id: number) => {
    const selectedIndex = selected.indexOf(id)
    if (selectedIndex === -1) return setSelected([...selected, id])
    const newSelected = selected.filter((_, index) => index !== selectedIndex)
    setSelected(newSelected)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked
      ? setSelected(adminSelectedSubscriptionsCatalog.map((item) => item.id as number))
      : setSelected([])
  }

  const handleRemoveBtn = () => {
    dispatch(toggleModal(true))
  }

  const handleDelete = () => {
    dispatch(deleteFromAdminSelectedSubscriptionsCatalog(selected))
  }

  const handleAddLicenseBtnClick = () => {
    dispatch(setIsCatalogModalOpen(true))
  }

  const handleModalClose = () => {
    dispatch(toggleModal(false))
  }

  if (error)
    return (
      <StyledGridContainer>
        <Typography>Something went wrong</Typography>
      </StyledGridContainer>
    )

  if (_.isEmpty(adminSelectedSubscriptionsCatalog))
    return (
      <>
        <StyledGridContainer>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
              <EmptyCatalogIcon />
              <Box sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '21px', fontWeight: 300, marginBottom: '8px' }}>
                  You’ve not added any licenses yet
                </Typography>
                <Typography sx={{ color: Colors.blackLight }}>Click the button bellow to add new</Typography>
              </Box>
              <Button sx={{ maxWidth: '326px' }} onClick={handleAddLicenseBtnClick}>
                <AddIcon />
                Add licenses
              </Button>
            </Box>
          )}
        </StyledGridContainer>
        {isCatalogModalOpen && <AddCatalogSubscriptionsModal />}
      </>
    )

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'start', paddingBottom: '20px', gap: '30px' }}>
        <Button sx={{ width: '155px' }} onClick={handleAddLicenseBtnClick}>
          <AddIcon />
          Add licenses
        </Button>
        {!_.isEmpty(selected) && (
          <Button onClick={handleRemoveBtn} sx={{ width: '146px' }} appearance={ButtonAppearance.secondary}>
            <DeleteIcon />
            Remove
          </Button>
        )}
      </Box>
      <Box sx={{ height: '90%', overflow: 'auto' }}>
        <Table
          columns={columns}
          items={selectedSubscriptionsCatalogWithExpiration}
          checkboxSelection
          selected={selected}
          onHandleSelected={handleSelect}
          onHandleSelectedAll={handleSelectAll}
        />
      </Box>
      <Modal open={isModalOpen} onClose={handleModalClose} sx={{ width: '595px', height: '371px' }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
          <StyledModalHeader>
            <StyledModalTitle>Remove these licenses?</StyledModalTitle>
            <StyledCloseButton onClick={handleModalClose}>&times;</StyledCloseButton>
          </StyledModalHeader>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
            <CatalogModalWarningIcon />
            <Typography>You will be able to add them again from the catalog</Typography>
          </Box>
          <StyledModalFooter>
            <MuiBtn onClick={handleDelete} variant='text' sx={{ width: '88px' }} disabled={loading}>
              Remove
            </MuiBtn>
            <Button onClick={handleModalClose} sx={{ width: '88px' }}>
              Cancel
            </Button>
          </StyledModalFooter>
        </Box>
      </Modal>
      {isCatalogModalOpen && <AddCatalogSubscriptionsModal />}
    </>
  )
}

export default CatalogPage
