import { Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser'

import { CLIENT_URLS, Enviroment, getEnvByUrl } from '../constants/api.constant'

/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

export const B2C_POLICIES = {
  names: {
    signUpSignIn: 'B2C_1A_SIGNUP_SIGNIN'
  },
  authorities: {
    signUpSignIn: {
      authority: 'https://ubtechportal.b2clogin.com/ubtechportal.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN'
    }
  },
  authorityDomain: 'ubtechportal.b2clogin.com'
}

/**
 * Configuration object to be passed to MSAL instance on creation.
 * For a full list of MSAL.js configuration parameters, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
 */
export const msalConfig: Configuration = {
  auth: {
    clientId: 'd38cb38e-2c65-4cb1-abd9-e6431d99bac9', // This is the ONLY mandatory field that you need to supply.
    authority: B2C_POLICIES.authorities.signUpSignIn.authority,
    knownAuthorities: [B2C_POLICIES.authorityDomain], // Mark your B2C tenant's domain as trusted.
    redirectUri: CLIENT_URLS[getEnvByUrl()], // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
    postLogoutRedirectUri: 'https://login.microsoftonline.com/common/oauth2/logout', // Indicates the page to navigate after logout.
    navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: true // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return
        }
        switch (level) {
          case LogLevel.Error:
            // console.error(message)
            return
          case LogLevel.Info:
            // console.info(message)
            return
          case LogLevel.Verbose:
            // console.debug(message)
            return
          case LogLevel.Warning:
            // console.warn(message)
            return
          case LogLevel.Trace:
            return
        }
      }
    }
  }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
  scopes: ['openid']
}
