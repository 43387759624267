import { size } from 'lodash'
import React from 'react'

const MainLogoIcon = () => (
  <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
    <img src="https://rgubtechportalb900.blob.core.windows.net/portal/main_logo.png" 
      alt="main logo"
      style={{ width: '140%' }}/>
  </div>

)

export default MainLogoIcon


