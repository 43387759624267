import { Box } from '@mui/material'
import React, { useEffect } from 'react'

import UsersManagmentForm from '../components/usersManagementForm/usersManagementForm.component'
import { useAppDispatch } from '../hooks/redux.hook'
import { setActiveUser } from '../state/features/admin.feature'
import { setIsRedirectWithoutRouting } from '../state/features/global.feature'

const EditUser = () => {
  const dispatch = useAppDispatch()
  useEffect(() => {
    return () => {
      dispatch(setActiveUser(null))
      dispatch(setIsRedirectWithoutRouting(false))
    }
  }, [])
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
      <UsersManagmentForm />
    </Box>
  )
}
export default EditUser
