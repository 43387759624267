import { useLocation } from 'react-router-dom'

export const usePathname = () => {
  const { pathname } = useLocation()

  return pathname
    .split('/')
    .filter((el) => el !== '')
    .map((el) => `/${el}`)
}
