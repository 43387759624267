import React from 'react'
import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import { AuthenticationResult, PublicClientApplication } from '@azure/msal-browser'
import _ from 'lodash'
import { ThemeProvider } from '@mui/system'

import './index.css'
import App from './app/App'
import { store } from './app/state/store'
import { msalConfig } from './app/services/auth.service'
import { theme } from './app/constants/theme.constant'

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(msalConfig)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // FYI: StrictMode in dev env inits components with hooks twice (https://github.com/facebook/react/issues/15074#issuecomment-471197572)
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <Provider store={store}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  </MsalProvider>
  // </React.StrictMode>
)
