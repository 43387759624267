import React, { useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import _ from 'lodash'

import Header from './components/header/header.component'
import Navbar from './components/navbar/navbar.component'
import Main from './components/main/main.component'
import { useAppSelector, useAppDispatch } from './hooks/redux.hook'
import { login } from './state/features/user.feature'
import LoginErrorPage from './pages/loginError.page'
import { ERROR_CODE } from './constants/api.constant'
import { StyledGridContainer } from './constants/styles.constant'

const App = () => {
  const { accounts } = useMsal()
  const { user, error, loading } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (!_.isEmpty(accounts)) {
      dispatch(login())
    }
  }, [accounts])

  if (loading)
    return (
      <StyledGridContainer>
        <CircularProgress />
      </StyledGridContainer>
    )

  if (error?.errorCode === ERROR_CODE.unauthorized) return <LoginErrorPage />

  return (
    <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
      {user && (
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Navbar />
          <Header />
          <Main />
        </Box>
      )}
    </MsalAuthenticationTemplate>
  )
}

export default App
