export const LICENCE_SUBS_TYPES = {
  monthly: 'Monthly',
  yearly: 'Yearly',
  twoYears: '2 Year',
  threeYears: '3 Year'
}

export const TERM_DURATION = {
  P1M: '1 Month',
  P1Y: '1 Year',
  P2Y: '2 Years',
  P3Y: '3 Year'
}

export enum CellType {
  text,
  input
}

export const ROLE_TYPES = {
  admin: 'admin',
  user: 'user',
  salesRep: 'salesRep'
}

export const ROLE_TITLES = {
  admin: 'Admin',
  user: 'General user',
  salesRep: 'Sales representative'
}

export const SERVICES = {
  microsoft365: 'Microsoft 365',
  azure: 'Azure'
}

export type Roles = keyof typeof ROLE_TITLES

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export enum PERMISSIONS {
  write = 'write',
  read = 'read'
}
