import React, { useEffect, useState } from 'react'
import { TextField, Box, Menu, CircularProgress, Typography, ButtonGroup } from '@mui/material'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import _ from 'lodash'
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'

import Table from '../components/table/table.component'
import { CellType, EMAIL_REGEX } from '../constants/global.constant'
import Button from '../components/button/button.component'
import Modal from '../components/modal/modal.component'
import {
  StyledCloseButton,
  StyledGridContainer,
  StyledInputLabel,
  StyledModalHeader,
  StyledModalTitle,
  StyledMuiOutlinedButton
} from '../constants/styles.constant'
import { useAppDispatch, useAppSelector } from '../hooks/redux.hook'
import Input from '../components/input/input.component'
import { toggleModal } from '../state/features/ui.feature'
import { fetchLogs, sendLogs } from '../state/features/admin.feature'
import ICONS from '../constants/icons.constant'
import { ButtonAppearance, Colors } from '../constants/appearance.constant'
import Search from '../components/search/search.component'

const { SendIcon } = ICONS

const columns = [
  { displayName: 'Date', key: 'flatDateOfChange', cellType: CellType.text, width: '10%' },
  { displayName: 'Tenant', key: 'tenantName', cellType: CellType.text, width: '15%' },
  { displayName: 'License id', key: 'msSubscriptionId', cellType: CellType.text, width: '25%' },
  { displayName: 'License', key: 'subscriptionName', cellType: CellType.text, width: '25%' },
  { displayName: 'Previous quantity', key: 'prevQuantity', cellType: CellType.text, width: '5%' },
  { displayName: 'New quantity', key: 'newQuantity', cellType: CellType.text, width: '5%' },
  { displayName: 'Changed By', key: 'userName', cellType: CellType.text, width: '10%' }
]

// eslint-disable-next-line complexity
const LogsPage = () => {
  const dispatch = useAppDispatch()

  const { logs, loading, error } = useAppSelector((state) => state.admin)

  const [formData, setFormData] = useState<Record<string, Date | null>>({
    from: null,
    to: null
  })
  const [formDataErrors, setFormDataErrors] = useState<Record<string, boolean>>({
    from: false,
    to: false
  })
  const [email, setEmail] = useState<string>('')
  const [errorFlag, setErrorFlag] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const [buttonContent, setButtonContent] = useState<string>('')
  const [inputValue, setInputValue] = useState<string>('')

  const isEmailValid = EMAIL_REGEX.test(email)

  const isShowEmailError = (context: any) => (_.isEmpty(context) || !isEmailValid) && errorFlag

  const { isModalOpen } = useAppSelector((state) => state.ui)
  const currentDateUTC = new Date().toISOString()

  useEffect(() => {
    handleInitFetch()
  }, [])

  const handleInitFetch = () => {
    dispatch(
      fetchLogs({
        from: subMonths(new Date(), 1).toISOString(),
        to: currentDateUTC
      })
    )
    resetDates()
    setButtonContent('')
  }

  const resetDates = () => {
    const newFormObj = _.chain(formData)
      .keys()
      .reduce((accumulator, key) => ({ ...accumulator, [key]: null }), {})
      .value()
    setFormData(newFormObj)
  }

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleLogsSubmit = () => {
    if (isEmailValid) {
      dispatch(sendLogs({ email, logs }))
      handleModalClose()
    } else {
      setErrorFlag(true)
    }
  }

  const handleFormSubmit = () => {
    const isReadyToDispatch = _.chain(formData)
      .values()
      .every((value) => !_.isNil(value))
      .value()
    if (isReadyToDispatch) {
      dispatch(
        fetchLogs({
          from: formData?.from?.toISOString() || currentDateUTC,
          to: formData?.to?.toISOString() || currentDateUTC
        })
      )
      setButtonContent(
        `${format(new Date(formData.from || ''), 'dd/MM/yyyy')} - ${format(new Date(formData.to || ''), 'dd/MM/yyyy')}`
      )
      setAnchorEl(null)
    }
  }

  const handleSendLogsClick = () => {
    dispatch(toggleModal(true))
  }

  const handleModalClose = () => {
    setErrorFlag(false)
    setEmail('')
    dispatch(toggleModal(false))
  }

  const renderDateRangeBtnContent = () => {
    return buttonContent ? buttonContent : 'Date Range'
  }

  const handleFormData = (newValue: Date | null, key: string) => {
    setFormData({ ...formData, [key]: newValue })
  }

  const handleInputError = (error: boolean, key: string) => {
    const prevObj = { ...formDataErrors }
    const newObj = { ...formDataErrors, [key]: error }

    const isEqual = JSON.stringify(prevObj) === JSON.stringify(newObj)

    if (!isEqual) {
      setFormDataErrors(newObj)
    }
  }

  const parsedLogs = _.map(logs, (log) => ({
    ...log,
    flatDateOfChange: format(new Date(log.createdAt), 'dd/MM/yyyy: HH:mm')
  }))

  const filteredItems = _.filter(parsedLogs, (parsedLog) =>
    parsedLog.tenantName.toLowerCase().includes(inputValue.toLowerCase())
  )

  return (
    <>
      <Box sx={{ display: 'flex', gap: '15px', alignItems: 'end' }}>
        <Box width={270}>
          <Search
            value={inputValue}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInputValue(e.target.value)
            }}
          />
        </Box>
        <ButtonGroup>
          <StyledMuiOutlinedButton
            variant='outlined'
            active={Boolean(anchorEl) || Boolean(buttonContent)}
            onClick={handleMenuOpen}
          >
            {renderDateRangeBtnContent()}
          </StyledMuiOutlinedButton>
          {buttonContent ? (
            <StyledMuiOutlinedButton
              sx={{ fontSize: '18px' }}
              variant='outlined'
              active={Boolean(anchorEl) || Boolean(buttonContent)}
              onClick={handleInitFetch}
            >
              &times;
            </StyledMuiOutlinedButton>
          ) : (
            ''
          )}
        </ButtonGroup>
        {!_.isEmpty(logs) && (
          <Button onClick={handleSendLogsClick} sx={{ width: 'fit-content' }}>
            <SendIcon />
            Send Logs
          </Button>
        )}
      </Box>
      <Typography fontSize={12} marginTop={2} color={Colors.grey}>
        ** The list shows only transactions that got approved by MS API **
      </Typography>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{ sx: { minHeight: '216px', padding: '20px' } }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '65px' }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ display: 'flex', gap: '20px' }}>
              {Object.keys(formData).map((key) => {
                return (
                  <Box key={key} sx={{ maxWidth: '175px' }}>
                    <StyledInputLabel>{key}</StyledInputLabel>
                    <DatePicker
                      minDate={new Date('01-01-2020')}
                      inputFormat='dd/MM/yyyy'
                      value={formData[key as keyof typeof formData]}
                      onChange={(newValue) => handleFormData(newValue, key)}
                      renderInput={(params) => {
                        handleInputError(Boolean(params.error), key)
                        return (
                          <Box>
                            <TextField size='small' {...params} />
                          </Box>
                        )
                      }}
                    />
                  </Box>
                )
              })}
            </Box>
          </LocalizationProvider>
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: '15px' }}>
            <Button
              id='reset'
              onClick={() => resetDates()}
              appearance={ButtonAppearance.secondary}
              sx={{ width: 'fit-content' }}
            >
              Reset
            </Button>
            <Button
              id='reset'
              sx={{ width: 'fit-content' }}
              disabled={!formData.from || !formData.to || formDataErrors.from || formDataErrors.to}
              onClick={handleFormSubmit}
            >
              Send
            </Button>
          </Box>
        </Box>
      </Menu>
      <Box sx={{ height: '85%' }}>
        {error && _.isEmpty(logs) ? (
          <StyledGridContainer>
            <Typography>Something went wrong</Typography>
          </StyledGridContainer>
        ) : _.isEmpty(logs) ? (
          <StyledGridContainer>{loading ? <CircularProgress /> : <Typography>No logs</Typography>}</StyledGridContainer>
        ) : (
          <Box sx={{ height: '100%', overflow: 'auto' }}>
            <Table columns={columns} items={filteredItems} initOrderBy='flatDateOfChange' />
          </Box>
        )}
      </Box>
      <Modal sx={{ minWidth: '451px' }} open={isModalOpen} onClose={handleModalClose}>
        <StyledModalHeader>
          <StyledModalTitle>Send Logs Report</StyledModalTitle>
          <StyledCloseButton onClick={handleModalClose}>&times;</StyledCloseButton>
        </StyledModalHeader>
        <Box sx={{ display: 'flex', height: '150px', flexDirection: 'column', justifyContent: 'center' }}>
          <Box>
            <StyledInputLabel>Email Address</StyledInputLabel>
            <Box sx={{ display: 'flex' }}>
              <Input
                error={isShowEmailError(email)}
                errorMessage={'Please type a valid email'}
                value={email}
                handleChange={(event: React.ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)}
                sx={{ height: '36px', borderRadius: '5px 0 0 5px' }}
              />
              <Button onClick={handleLogsSubmit} sx={{ borderRadius: '0 5px 5px 0', width: '120px' }}>
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  )
}

export default LogsPage
