export const TOAST_MESSAGES = {
  empty: '',
  success: 'Your request has been sent',
  failed: 'Something went wrong, please try again',
  syncTenantsSuccess: 'Tenants successfully synced',
  editUserSuccess: 'User data successfully changed',
  deleteUserSuccess: 'User was deleted',
  sendLogsSuccess: 'Logs were successfully sent',
  deleteSubsFromSelectedCatalogSuccess: 'License(s) was successfully deleted',
  addingSubsFromSelectedCatalogSuccess: 'License(s) was successfully added',
  getOfferSuccess: 'Hi, your request is being processed and we will contact you shortly'
}
