import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import API from '../../services/ajax.service'
import { API_ENDPOINTS } from '../../constants/api.constant'

export const fetchOverviewSubscriptionsData = createAsyncThunk(
  'overview/fetchOverviewSubscriptionsData',
  (msTenantId: string, { rejectWithValue }) =>
    API.getInstance()
      .get(`${API_ENDPOINTS.OVERVIEW_FETCH}/${msTenantId}`)
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

interface IGlobalState {
  loading: boolean
  error: boolean
  overviewSubscriptionData: any
  postEditSubscriptionMessage: string | null
  isRedirectWithoutRouting: boolean
}

const initialState: IGlobalState = {
  error: false,
  loading: false,
  overviewSubscriptionData: null,
  postEditSubscriptionMessage: null,
  isRedirectWithoutRouting: false
}

export const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    setIsRedirectWithoutRouting: (state, action: PayloadAction<boolean>) => {
      state.isRedirectWithoutRouting = action.payload
    }
  },
  extraReducers: {
    [fetchOverviewSubscriptionsData.pending.type]: (state) => {
      state.loading = true
      state.error = false
    },
    [fetchOverviewSubscriptionsData.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.overviewSubscriptionData = payload.data
    },
    [fetchOverviewSubscriptionsData.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    }
  }
})

// Action creators are generated for each case reducer function
export const { setIsRedirectWithoutRouting } = globalSlice.actions

export default globalSlice.reducer
