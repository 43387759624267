import React from 'react'
import { Autocomplete, Box, Checkbox, Paper, styled, TextField, Typography, ButtonGroup, Tooltip } from '@mui/material'
import _ from 'lodash'

import { Colors } from '../../constants/appearance.constant'
import ICONS from '../../constants/icons.constant'
import { ITenant } from '../../state/features/user.feature'
import { StyledMuiOutlinedButton } from '../../constants/styles.constant'

const { TenantIcon } = ICONS

const StyledPaper = styled(Paper)({
  width: '100%',
  border: '1px solid',
  borderColor: Colors.blueOpacityMedium,
  borderRadius: '8px'
})

const StyledPaperTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 300
})

const StyledInputLabel = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  color: Colors.grey,
  letterSpacing: '1px',
  'text-transform': 'uppercase',
  marginBottom: '5px'
})

const StyledPaperHeader = styled('div')({
  marginBottom: '30px'
})

const StyledPaperBody = styled('div')({})

const StyledTextError = styled('p')({
  color: Colors.red,
  fontSize: '13px',
  marginTop: '5px'
})

// eslint-disable-next-line functional/no-mixed-type
interface IAddSalesRepTenantBoxProps {
  relatedTenants: ITenant[]
  selectedTenants: string[]
  onSalesRepSelectedTenantsChange: (value: string[]) => void
  isShowError: (context: any) => boolean
}

const AddSalesRepTenantBox = ({
  relatedTenants,
  onSalesRepSelectedTenantsChange,
  selectedTenants,
  isShowError
}: IAddSalesRepTenantBoxProps) => {
  const parsedRelatedTenants = _.map(relatedTenants, (relatedTenant) => relatedTenant.domain)

  return (
    <StyledPaper elevation={0} sx={{ padding: '20px 20px 30px 20px' }}>
      <StyledPaperHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: '8px', color: Colors.dark }}>
              <TenantIcon />
            </Box>
            <StyledPaperTitle>Tenants</StyledPaperTitle>
          </Box>
        </Box>
      </StyledPaperHeader>
      <StyledPaperBody>
        <Box sx={{ display: 'flex', gap: '30px' }}>
          <Box>
            <StyledInputLabel>Related tenants</StyledInputLabel>
            <Box sx={{ width: '264px' }}>
              <Autocomplete
                disableClearable
                ChipProps={{ sx: { display: 'none' } }}
                size='small'
                multiple
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                options={parsedRelatedTenants}
                value={selectedTenants}
                onChange={(event: any, newValue: string[]) => {
                  onSalesRepSelectedTenantsChange(newValue)
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox style={{ marginRight: 8 }} checked={selected} value={option} />
                    {option}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField {...params} error={isShowError(selectedTenants)} placeholder={'Select from the list'} />
                )}
              />
              {isShowError(selectedTenants) && <StyledTextError>Please select an option</StyledTextError>}
            </Box>
          </Box>
          <Box>
            <StyledInputLabel>Selected tenants</StyledInputLabel>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
              {_.map(selectedTenants, (selectedTenant) => (
                <ButtonGroup key={selectedTenant} fullWidth>
                  <StyledMuiOutlinedButton disableRipple active variant='outlined' sx={{ cursor: 'default' }}>
                    <Tooltip title={selectedTenant}>
                      <Typography
                        sx={{
                          color: Colors.bluePrimary,
                          overflow: 'hidden',
                          maxWidth: '150px',
                          textOverflow: 'ellipsis'
                        }}
                      >
                        {selectedTenant}
                      </Typography>
                    </Tooltip>
                  </StyledMuiOutlinedButton>
                  <StyledMuiOutlinedButton
                    sx={{ fontSize: '20px', fontWeight: '300', width: '20px' }}
                    active
                    variant='outlined'
                    disableRipple
                    onClick={() =>
                      onSalesRepSelectedTenantsChange(_.filter(selectedTenants, (item) => item !== selectedTenant))
                    }
                  >
                    &times;
                  </StyledMuiOutlinedButton>
                </ButtonGroup>
              ))}
              {!_.isEmpty(selectedTenants) && (
                <StyledMuiOutlinedButton
                  active={false}
                  disableRipple
                  variant='outlined'
                  fullWidth
                  onClick={() => onSalesRepSelectedTenantsChange([])}
                >
                  Clear All
                </StyledMuiOutlinedButton>
              )}
            </Box>
          </Box>
        </Box>
      </StyledPaperBody>
    </StyledPaper>
  )
}

export default AddSalesRepTenantBox
