export enum Colors {
  white = '#FFFFFF',
  gradient = 'linear-gradient(12deg, #020202 -6%, #020202 106%)',
  dark = '#2E384D',
  whiteOpacityLow = 'rgba(255, 255, 255, 0.08)',
  pinkSecondary = '#FA67BE',
  turquoiseSecondary = '#21D6D9',
  indigoSecondary = '#586DF7',
  blueOpacityLow = 'rgba(46, 91, 255, 0.08)',
  blueOpacityMedium = 'rgba(46, 91, 255, 0.16)',
  blueOpacityHigh = 'rgba(46, 91, 255, 0.24)',
  blueOpacityHover = 'rgba(46, 91, 255, 0.48)',
  bluePrimary = '#2E5BFF',
  black = '#333333',
  purpleLight = '#387f9e',
  purplePrimary = '#04c9ef',
  grey = '#B0BAC9',
  greyLight = '#ECECED',
  red = '#FF2D55',
  indigoOpacityMedium = 'rgba(88, 109, 247, 0.16)',
  turquoiseOpacityMedium = 'rgba(33, 214, 217, 0.16)',
  pinkLight = 'rgba(249, 92, 182, 0.16)',
  blackLight = 'rgba(46, 56, 77, 0.56)',
  blackOpacityMedium = 'rgba(0, 0, 0, 0.48)'
}

export enum FontFamilies {
  regular = 'RubikRegular',
  light = 'RubikLight',
  bold = 'RubikBold'
}

export enum ButtonAppearance {
  primary,
  secondary
}

export enum ButtonShape {
  round,
  rect
}

export enum ToastAppearance {
  successfully,
  unsuccessfully
}

export enum OverviewBoxThemes {
  microsoft,
  azure,
  support
}
