import { createTheme } from '@mui/material'
import type {} from '@mui/x-date-pickers/themeAugmentation'

import { Colors } from './appearance.constant'

export const theme = createTheme({
  typography: {
    fontFamily: ['Rubik', 'sans-serif'].join(','),
    fontSize: 14
  },
  components: {
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: Colors.bluePrimary
          },
          '&.MuiCheckbox-indeterminate': {
            color: Colors.bluePrimary
          },
          padding: 0
        },
        indeterminate: {
          color: Colors.bluePrimary,
          '&.Mui-checked': {
            color: Colors.bluePrimary
          }
        }
      }
    },
    MuiCircularProgress: {
      styleOverrides: {
        root: {
          color: Colors.bluePrimary
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          margin: 0,
          '& .MuiFormControlLabel-label': {
            color: Colors.dark,
            marginLeft: '8px'
          }
        }
      }
    },
    MuiPaper: {
      defaultProps: {
        elevation: 1
      },
      styleOverrides: {
        root: {
          borderRadius: '8px'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: Colors.dark
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          color: Colors.white
        },
        primary: {
          color: Colors.white
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: Colors.dark,
          border: `1px solid ${Colors.blueOpacityMedium}`,
          borderRadius: '5px',
          padding: '2px 15px',
          cursor: 'default',
          ':hover': {
            border: `1px solid ${Colors.blueOpacityHover}`
          },
          '&.Mui-error': {
            border: '1px solid red'
          }
        }
      }
    },
    MuiList: {
      styleOverrides: {
        root: {
          color: Colors.dark,
          padding: 0
        }
      }
    },
    MuiMenuItem: {
      defaultProps: {
        autoFocus: false
      },
      styleOverrides: {
        root: {
          ':focus': {
            backgroundColor: Colors.white
          },
          ':hover': {
            color: Colors.bluePrimary,
            backgroundColor: Colors.blueOpacityLow
          },
          '&.Mui-selected': {
            color: Colors.bluePrimary,
            backgroundColor: Colors.blueOpacityLow
          }
        }
      }
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 0,
          '&.Mui-checked': {
            color: Colors.bluePrimary // primary color of all radio buttons
          },
          '&.MuiRadio-colorPrimary': {
            ':hover': {
              backgroundColor: 'unset !important' // disable of background color on hover
            }
          }
        }
      }
    },
    MuiButton: {
      defaultProps: { disableRipple: true, disableElevation: true },
      styleOverrides: {
        root: {
          fontSize: '15px',
          fontWeight: '400',
          'text-transform': 'none'
        },
        outlined: {
          borderColor: Colors.blueOpacityHigh,
          height: '36px',
          color: Colors.dark,
          ':hover': {
            color: Colors.bluePrimary,
            borderColor: Colors.bluePrimary,
            backgroundColor: Colors.white
          }
        },
        text: {
          color: Colors.grey,
          textTransform: 'uppercase',
          fontSize: '12px',
          fontWeight: '500',
          letterSpacing: '1px',
          lineHeight: 0,
          ':hover': {
            backgroundColor: Colors.blueOpacityLow
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: Colors.white
          }
        }
      }
    },
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .MuiAutocomplete-option': {
            ':hover': {
              backgroundColor: Colors.blueOpacityLow,
              color: Colors.bluePrimary
            }
          }
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: 'none'
            },
            '&:hover fieldset': {
              border: 'none'
            },
            '&.Mui-focused fieldset': {
              borderColor: 'none'
            }
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: Colors.blueOpacityLow
        }
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          ':hover': {
            backgroundColor: Colors.blueOpacityHover
          },
          '&.Mui-selected': {
            backgroundColor: Colors.bluePrimary
          },
          ':focus': {
            '&.Mui-selected': {
              backgroundColor: Colors.bluePrimary
            }
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px'
        }
      }
    }
  }
})
