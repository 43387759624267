import React from 'react'
import { Box, Paper, styled, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

import { Colors, OverviewBoxThemes } from '../../constants/appearance.constant'
import ICONS from '../../constants/icons.constant'
import { ROUTES } from '../../constants/routes.constant'
import Button from '../button/button.component'

const { ArrowRightIcon } = ICONS

const backgroundColorSelector = (appearance: OverviewBoxThemes) => {
  switch (appearance) {
    case OverviewBoxThemes.microsoft:
      return {
        paperBgColor: Colors.blueOpacityLow,
        btnBgColor: Colors.indigoSecondary
      }
    case OverviewBoxThemes.azure:
      return {
        paperBgColor: Colors.pinkLight,
        btnBgColor: Colors.pinkSecondary
      }
    case OverviewBoxThemes.support:
      return {
        paperBgColor: Colors.turquoiseOpacityMedium,
        btnBgColor: Colors.turquoiseSecondary
      }
  }
}

interface IStyledBackgroundProps {
  appearance: OverviewBoxThemes
}

const StyledPaper = styled(Paper)(({ appearance }: IStyledBackgroundProps) => ({
  display: 'flex',
  'flex-direction': 'column',
  justifyContent: 'space-between',
  width: '280px',
  height: '194px',
  padding: '16px 32px',
  margin: '32px',
  backgroundColor: backgroundColorSelector(appearance).paperBgColor
}))

const Title = styled(Typography)({
  fontSize: '13px',
  letterSpacing: '1px',
  color: Colors.blackLight,
  textTransform: 'uppercase'
})

const BodyText = styled(Typography)({
  color: Colors.dark,
  fontSize: '48px',
  fontWeight: '300',
  lineHeight: 1
})

const SubBodyText = styled(Typography)({
  color: Colors.dark
})

const BodyDescription = styled(Typography)({
  color: Colors.dark
})

export interface IOverviewBoxProps {
  title?: string
  appearance?: OverviewBoxThemes
  path?: string
  bodyText?: string
  subBodyText?: string
  bodyDescriptionText?: string
  buttonText?: string
  disabled?: boolean
  error?: boolean
}

const OverviewBox = ({
  title,
  bodyText,
  subBodyText,
  bodyDescriptionText,
  buttonText,
  appearance = OverviewBoxThemes.microsoft,
  path = ROUTES.OVERVIEW,
  disabled,
  error
}: IOverviewBoxProps) => {
  const navigate = useNavigate()

  return (
    <StyledPaper appearance={appearance} elevation={0}>
      <Title>{title}</Title>
      {disabled ? (
        <Typography>This service isn’t related to you, please contact support</Typography>
      ) : error ? (
        <BodyDescription>{'Something went wrong, try again later'}</BodyDescription>
      ) : (
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', paddingBottom: '14px' }}>
            <BodyText>{bodyText}</BodyText>
            <SubBodyText>{subBodyText}</SubBodyText>
          </Box>
          <BodyDescription>{bodyDescriptionText}</BodyDescription>
        </Box>
      )}
      <Button
        disabled={disabled}
        onClick={() => navigate(path)}
        sx={{
          width: '117px',
          backgroundColor: backgroundColorSelector(appearance).btnBgColor,
          '&:hover': {
            backgroundColor: backgroundColorSelector(appearance).btnBgColor
          }
        }}
      >
        {disabled ? 'Contact' : buttonText}
        <ArrowRightIcon />
      </Button>
    </StyledPaper>
  )
}

export default OverviewBox
