import LogoutIcon from '../assets/icons/logout.icon'
import MainLogoIcon from '../assets/icons/mainLogo.icon'
import MenuIcon from '../assets/icons/menu.icon'
import MenuCloseIcon from '../assets/icons/menuClose.icon'
import Microsoft365Icon from '../assets/icons/microsoft365.icon'
import OverviewIcon from '../assets/icons/overview.icon'
import ArrowRightIcon from '../assets/icons/arrowRight.icon'
import AddIcon from '../assets/icons/add.icon'
import ReportIcon from '../assets/icons/report.icon'
import EditIcon from '../assets/icons/edit.icon'
import AlertIcon from '../assets/icons/alert.icon'
import ArrowDownIcon from '../assets/icons/arrowDown.icon'
import ArrowSelectIcon from '../assets/icons/arrowSelect.icon'
import CheckIcon from '../assets/icons/check.icon'
import SearchIcon from '../assets/icons/search.icon'
import UserIcon from '../assets/icons/user.icon'
import DeleteIcon from '../assets/icons/delete.icon'
import TenantIcon from '../assets/icons/tenant.icon'
import SendIcon from '../assets/icons/send.icon'
import LogsIcon from '../assets/icons/logs.icon'
import CatalogIcon from '../assets/icons/catalog.icon'
import EmptyCatalogIcon from '../assets/icons/emptyCatalog.icon'
import CatalogModalWarningIcon from '../assets/icons/catalogModalWarning.icon'

export default {
  LogoutIcon,
  MainLogoIcon,
  MenuIcon,
  MenuCloseIcon,
  Microsoft365Icon,
  OverviewIcon,
  ArrowRightIcon,
  AddIcon,
  ReportIcon,
  EditIcon,
  AlertIcon,
  ArrowDownIcon,
  ArrowSelectIcon,
  CheckIcon,
  SearchIcon,
  UserIcon,
  DeleteIcon,
  TenantIcon,
  SendIcon,
  LogsIcon,
  CatalogIcon,
  EmptyCatalogIcon,
  CatalogModalWarningIcon
}
