import React from 'react'
import { ListItem, ListItemButton, ListItemIcon, ListItemText, styled } from '@mui/material'

import { Colors } from '../../../constants/appearance.constant'
import { useAppSelector } from '../../../hooks/redux.hook'

// eslint-disable-next-line functional/no-mixed-type
interface INavbarItemProps {
  title: string
  children: JSX.Element
  selected?: boolean
  onClickHandle?: () => void
}

const StyledListItemText = styled(ListItemText)`
  color: ${Colors.white};
  font-size: 25px;
  margin-left: 30px;
`
const StyledListItem = styled(ListItem)`
  &.Mui-selected {
    background-color: ${Colors.whiteOpacityLow};
    &::before {
      content: '';
      position: absolute;
      left: 1px;
      background-color: ${Colors.white};
      height: 72px;
      width: 2px;
    }
  }
`
const StyledListItemButton = styled(ListItemButton)`
  padding-left: 20px;
`

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: 0,
  color: Colors.white
})

const NavbarItem = ({ children, title, selected = false, onClickHandle }: INavbarItemProps) => {
  const { isNavBarOpen } = useAppSelector((state) => state.ui)
  return (
    <StyledListItem onClick={onClickHandle} selected={selected} disablePadding>
      <StyledListItemButton sx={{ height: 72 }}>
        <StyledListItemIcon>{children}</StyledListItemIcon>
        {isNavBarOpen && <StyledListItemText>{title}</StyledListItemText>}
      </StyledListItemButton>
    </StyledListItem>
  )
}
export default NavbarItem
