import { ERROR_CODE } from '../../constants/api.constant'
import { setUserUnauthorized } from '../features/user.feature'

export const errorMiddleware = (state: any) => (next: any) => (action: any) => {
  switch (action?.payload?.statusCode) {
    case ERROR_CODE.unauthorized:
      state.dispatch(setUserUnauthorized())
  }
  next(action)
}
