import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import API from '../../services/ajax.service'
import { API_ENDPOINTS, ERROR_CODE } from '../../constants/api.constant'
import { toggleToast } from './ui.feature'
import { TOAST_MESSAGES } from '../../constants/ui.constant'

export interface IService {
  length: number
  id: number
  name: string
  displayName: string
  permission: string
  emailSubscrition: boolean
}

export interface ITenant {
  id: number
  msTenantId: string
  name: string
  domain: string
  emailSubscription: boolean
}

export interface IUser {
  id: number
  name: string
  username: string
  email: string
  role: string
}

export interface ITenantService extends ITenant {
  services: IService[]
}
export interface IUserTenant extends IUser {
  tenants: ITenantService[]
}

interface IPostUsageReportData {
  userId: number
  tenantId: number
  emailSubscription: boolean
}

export const postUsageReport = createAsyncThunk(
  'user/postUsageReport',
  (postUsageReportData: IPostUsageReportData, { dispatch, rejectWithValue }) => {
    API.getInstance()
      .post(API_ENDPOINTS.SERVICES_SUBSCRIBE, postUsageReportData)
      .then((response) => {
        dispatch(setUsageReportData(response.data.data))
        dispatch(toggleToast({ isToastOpen: true, success: true, title: TOAST_MESSAGES.success }))
      })
      .catch((err) => {
        dispatch(toggleToast({ isToastOpen: true, success: false, title: TOAST_MESSAGES.failed }))
        return rejectWithValue(err)
      })
  }
)

export const login = createAsyncThunk('user/login', (_, { rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.LOGIN)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
)
export interface IUserError {
  error: boolean
  errorCode: number | null
  errorMessage: string
}

interface IUserState {
  user: IUser | null
  tenants: ITenantService[] | []
  activeTenant: ITenantService | null
  activeService: IService | Record<string, any>
  loading: boolean
  error: IUserError
}

const initialState: IUserState = {
  user: null,
  tenants: [],
  activeTenant: null,
  activeService: {},
  loading: false,
  error: {
    error: false,
    errorCode: null,
    errorMessage: ''
  }
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setActiveTenant: (state, action: PayloadAction<ITenantService>) => {
      state.activeTenant = action.payload
    },
    setActiveService: (state, action: PayloadAction<IService>) => {
      state.activeService = action.payload
    },
    setUsageReportData: (state, { payload }) => {
      state.activeService.emailSubscrition = payload.emailSubscription
    },
    setUserUnauthorized: (state) => {
      state.error.errorCode = ERROR_CODE.unauthorized
    }
  },
  extraReducers: {
    [login.pending.type]: (state) => {
      state.loading = true
      state.error.error = false
    },
    [login.fulfilled.type]: (state, { payload }) => {
      const sortedTenants = _.sortBy(payload?.tenants, (tenant) => tenant.domain)
      state.loading = false
      state.user = payload
      state.tenants = sortedTenants
      state.activeTenant = sortedTenants[0]
    },
    [login.rejected.type]: (state) => {
      state.loading = false
      state.error.error = true
    }
  }
})

export const { setActiveTenant, setActiveService, setUsageReportData, setUserUnauthorized } = userSlice.actions

export default userSlice.reducer
