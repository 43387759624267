import React from 'react'

const ReportIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M19 3c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5c-1.1 0-2-.9-2-2V5c0-1.1.9-2 2-2zm0 2H5v14h14V5zM9 12v5H7v-5h2zm8-5v10h-2V7h2zm-4 7v3h-2v-3h2zm0-4v2h-2v-2h2z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </g>
  </svg>
)

export default ReportIcon
