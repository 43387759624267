import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography, Button as MuiBtn } from '@mui/material'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

import Button from '../components/button/button.component'
import Table from '../components/table/table.component'
import ICONS from '../constants/icons.constant'
import { ButtonAppearance, Colors } from '../constants/appearance.constant'
import Search from '../components/search/search.component'
import { CellType } from '../constants/global.constant'
import { useAppDispatch, useAppSelector } from '../hooks/redux.hook'
import { fetchUsers, deleteUser, setActiveUser, syncAllTenants } from '../state/features/admin.feature'
import { ROUTES } from '../constants/routes.constant'
import EditUser from './editUser.page'
import { IUserTenant } from '../state/features/user.feature'
import { setIsRedirectWithoutRouting } from '../state/features/global.feature'
import {
  StyledCloseButton,
  StyledGridContainer,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalTitle
} from '../constants/styles.constant'
import Modal from '../components/modal/modal.component'
import { toggleModal } from '../state/features/ui.feature'

const { AddIcon, DeleteIcon, CatalogModalWarningIcon } = ICONS

const columns = [
  { displayName: 'Email', key: 'email', cellType: CellType.text, width: '20%' },
  { displayName: 'Role', key: 'flatRole', cellType: CellType.text, width: '10%' },
  { displayName: 'Related Tenants', key: 'flatTenants', cellType: CellType.text, width: '65%' }
]

const UsersManagmentPage = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [inputValue, setInputValue] = useState('')
  const [selected, setSelected] = useState<number[]>([])

  const { loading, users, activeUser, error } = useAppSelector((state) => state.admin)
  const { isModalOpen } = useAppSelector((state) => state.ui)

  useEffect(() => {
    dispatch(fetchUsers())
  }, [])

  useEffect(() => {
    setSelected([])
  }, [users])

  const handleSelect = (id: number) => {
    const selectedIndex = selected.indexOf(id)
    if (selectedIndex === -1) return setSelected([...selected, id])
    const newSelected = selected.filter((_, index) => index !== selectedIndex)
    setSelected(newSelected)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setSelected(users.map((user) => user.id)) : setSelected([])
  }

  const handleDelete = () => {
    dispatch(deleteUser(selected))
    setSelected([])
  }

  const handleUserEditIntention = (user: IUserTenant) => {
    dispatch(setIsRedirectWithoutRouting(true))
    dispatch(setActiveUser(user))
    setSelected([])
  }

  const handleSyncTenants = () => {
    dispatch(syncAllTenants())
  }

  const handleModalClose = () => {
    dispatch(toggleModal(false))
  }

  const handleRemoveBtn = () => {
    dispatch(toggleModal(true))
  }

  const parsedUsers = _.map(users, (user) => ({
    ...user,
    flatRole: user.role?.substring(0, 1).toUpperCase() + user.role.substring(1),
    flatTenants: _.map(user.tenants, (tenant) => tenant.domain).join(', ')
  }))

  const filteredItems = parsedUsers.filter((user) => user.email.toLowerCase().includes(inputValue.toLowerCase()))

  if (error)
    return (
      <StyledGridContainer>
        <Typography>Something went wrong</Typography>
      </StyledGridContainer>
    )

  if (loading && _.isEmpty(users))
    return (
      <StyledGridContainer>
        <CircularProgress />
      </StyledGridContainer>
    )

  return (
    <>
      {activeUser ? (
        <EditUser />
      ) : (
        <>
          <Box
            sx={{
              marginBottom: '46px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Box sx={{ maxWidth: '159px', marginRight: '20px' }}>
                <Button
                  appearance={ButtonAppearance.primary}
                  onClick={() => navigate(ROUTES.ADMIN + ROUTES.USERS + ROUTES.ADD_USER)}
                >
                  <AddIcon />
                  Add new user
                </Button>
              </Box>
              <Box sx={{ width: '159px', marginRight: '20px' }}>
                <Button
                  loading={loading && !_.isEmpty(users)}
                  loaderColor={Colors.dark}
                  onClick={handleSyncTenants}
                  appearance={ButtonAppearance.secondary}
                >
                  <Typography>Sync tenants</Typography>
                </Button>
              </Box>
              {selected.length > 0 && (
                <Box sx={{ maxWidth: '159px' }}>
                  <Button onClick={handleRemoveBtn} appearance={ButtonAppearance.secondary}>
                    <DeleteIcon />
                    Delete user
                  </Button>
                </Box>
              )}
            </Box>
            <Box sx={{ width: '300px' }}>
              <Search
                value={inputValue}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setInputValue(e.target.value)
                }}
              />
            </Box>
          </Box>
          <Box sx={{ height: '90%', overflow: 'auto' }}>
            <Table
              columns={columns}
              items={filteredItems}
              checkboxSelection
              selected={selected}
              onHandleSelected={handleSelect}
              onHandleSelectedAll={handleSelectAll}
              onClickAction={handleUserEditIntention}
              permission='write'
            />
          </Box>
          <Modal open={isModalOpen} onClose={handleModalClose} sx={{ width: '595px', height: '371px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <StyledModalHeader>
                <StyledModalTitle>Remove these users?</StyledModalTitle>
                <StyledCloseButton onClick={handleModalClose}>&times;</StyledCloseButton>
              </StyledModalHeader>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', alignItems: 'center' }}>
                <CatalogModalWarningIcon />
              </Box>
              <StyledModalFooter>
                <MuiBtn onClick={handleDelete} disabled={loading} variant='text' sx={{ width: '88px' }}>
                  Remove
                </MuiBtn>
                <Button onClick={handleModalClose} sx={{ width: '88px' }}>
                  Cancel
                </Button>
              </StyledModalFooter>
            </Box>
          </Modal>
        </>
      )}
    </>
  )
}

export default UsersManagmentPage
