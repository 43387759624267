import React, { useEffect } from 'react'
import { Box, CircularProgress, styled } from '@mui/material'
import _ from 'lodash'

import OverviewBox from '../components/overviewBox/overviewBox.component'
import { OverviewBoxThemes } from '../constants/appearance.constant'
import { ROUTES } from '../constants/routes.constant'
import { useAppDispatch, useAppSelector } from '../hooks/redux.hook'
import { fetchOverviewSubscriptionsData } from '../state/features/global.feature'

const Container = styled('div')({
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  flexWrap: 'wrap',
  overflow: 'overlay'
})

const OverviewPage = () => {
  const { overviewSubscriptionData, loading, error } = useAppSelector((state) => state.global)
  const { activeTenant } = useAppSelector((state) => state.user)
  const dispatch = useAppDispatch()

  useEffect(() => {
    activeTenant && dispatch(fetchOverviewSubscriptionsData(activeTenant.msTenantId))
  }, [activeTenant])

  return (
    <Container>
      {loading ? (
        <Box sx={{ height: '80%', display: 'grid', placeItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <OverviewBox
            title='Microsoft 365'
            bodyText={overviewSubscriptionData?.totalCount}
            subBodyText={`/ ${overviewSubscriptionData?.expiredCount} expired`}
            bodyDescriptionText={'Total Licenses'}
            buttonText='Manage'
            path={ROUTES.MICROSOFT}
            error={
              error || _.isNil(overviewSubscriptionData?.totalCount) || _.isNil(overviewSubscriptionData?.expiredCount)
            }
          />
          <OverviewBox title='Azure service view' appearance={OverviewBoxThemes.azure} disabled />
          <OverviewBox title='Ubtech support' appearance={OverviewBoxThemes.support} disabled />
        </>
      )}
    </Container>
  )
}

export default OverviewPage
