import React from 'react'
import { styled } from '@mui/material'

import ICONS from '../../constants/icons.constant'
import Input from '../input/input.component'

const { SearchIcon } = ICONS

const StyledSearch = styled('div')({
  height: '36px',
  position: 'relative',
  display: 'flex',
  alignItems: 'center'
})

const StyledSearchWrapper = styled('div')({
  position: 'absolute',
  top: '7px',
  left: '10px'
})

interface ISearchProps {
  value: string
  handleChange: any
  sx?: Record<string, any>
}

const Search = ({ value, handleChange, sx }: ISearchProps) => {
  return (
    <StyledSearch sx={sx}>
      <StyledSearchWrapper>
        <SearchIcon />
      </StyledSearchWrapper>
      <Input value={value} handleChange={handleChange} placeholder={'Search'} padding={'7px 15px 7px 40px'} />
    </StyledSearch>
  )
}

export default Search
