import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppBar, Menu, MenuItem, Typography, styled, Box, CircularProgress } from '@mui/material'
import _ from 'lodash'

import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook'
import { Colors } from '../../constants/appearance.constant'
import { NAV_SETTINGS } from '../../constants/navbar.constant'
import ICONS from '../../constants/icons.constant'
import Breadcrumbs from '../breadcrumbs/breadcrumbs.component'
import { ITenantService, setActiveTenant } from '../../state/features/user.feature'
import { CUSTOM_TITLES, ROUTES, ROUTES_TITLES } from '../../constants/routes.constant'
import Search from '../search/search.component'
import { useMsal } from '@azure/msal-react'
import { usePathname } from '../../hooks/usePathname.hook'
import { setActiveUser } from '../../state/features/admin.feature'

const { ArrowSelectIcon, CheckIcon, ArrowDownIcon, UserIcon, LogoutIcon } = ICONS

interface IStyledAppBarProps {
  open?: boolean
}

const StyledAppBar = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})<IStyledAppBarProps>(({ open, theme }) => ({
  width: open ? `calc(100% - ${NAV_SETTINGS.OPENED_WIDTH}px)` : `calc(100% - ${NAV_SETTINGS.CLOSED_WIDTH}px)`,
  maxHeight: '80px',
  borderRadius: 0,
  backgroundColor: Colors.white,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration[open ? 'enteringScreen' : 'leavingScreen']
  })
}))

const StyledIconContainer = styled('div')({
  width: 48,
  height: 48,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: 40,
  cursor: 'pointer',
  backgroundColor: Colors.bluePrimary
})

interface IStyledArrowIconProps {
  open: boolean
}

const StyledArrowIconContainer = styled('div')(({ open }: IStyledArrowIconProps) => ({
  transform: open ? 'rotate(180deg)' : '',
  transition: 'all 0.2s'
}))

const StyledMenuItem = styled(MenuItem)({
  padding: '10px',
  '&:hover': {
    backgroundColor: Colors.blueOpacityLow,
    color: Colors.indigoSecondary
  },
  '&.Mui-selected': {
    backgroundColor: Colors.blueOpacityLow,
    color: Colors.indigoSecondary
  }
})

const StyledHeaderContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '15px 25px'
})

const StyledTenantName = styled(Typography)({
  color: Colors.dark
})

const StyledMenuItemContainer = styled('div')({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center'
})

const StyledEndPointTitle = styled(Typography)({
  maxWidth: '350px',
  color: Colors.dark,
  fontSize: '24px',
  fontWeight: 300,
  overflow: 'hidden',
  textOverflow: 'ellipsis'
})

interface IStyledLinkButtonProps {
  disabled?: boolean
}

const StyledLinkButton = styled('button')(({ disabled }: IStyledLinkButtonProps) => ({
  backgroundColor: Colors.white,
  border: 'none',
  fontSize: '24px',
  fontWeight: 300,
  color: disabled ? Colors.dark : Colors.grey,
  cursor: disabled ? 'default' : 'pointer',
  '&:hover': {
    opacity: disabled ? '1' : '0.8'
  }
}))

const StyledSlashSymbol = styled(Typography)({ color: Colors.dark, fontSize: '22px', margin: '0 10px' })

interface IBreadcrumbTemplateProps {
  onClickHandle: () => void
  endPointTitle?: string
  paths: string[]
}

const BreadcrumbTemplate = ({ onClickHandle, endPointTitle, paths }: IBreadcrumbTemplateProps) => (
  <>
    <Box onClick={onClickHandle} sx={{ color: Colors.dark, transform: 'rotate(90deg)', marginRight: '10px' }}>
      <ArrowDownIcon />
    </Box>
    {_.map(paths, (path, index) => (
      <Fragment key={index}>
        <StyledLinkButton onClick={onClickHandle}>{ROUTES_TITLES[path]}</StyledLinkButton>
        <StyledSlashSymbol>/</StyledSlashSymbol>
      </Fragment>
    ))}
    <StyledEndPointTitle>{endPointTitle}</StyledEndPointTitle>
  </>
)

const Header = () => {
  const paths = usePathname()
  const { instance } = useMsal()
  const dispatch = useAppDispatch()
  const { isNavBarOpen } = useAppSelector((state) => state.ui)
  const { isRedirectWithoutRouting } = useAppSelector((state) => state.global)
  const { activeSubscription } = useAppSelector((state) => state.service)

  const { user, tenants, activeTenant, loading } = useAppSelector((state) => state.user)

  const accountPreview = !_.isEmpty(user?.name) ? user?.name.split('')[0].toUpperCase() : <UserIcon />

  const [tenantsAnchorEl, setTenantsAnchorEl] = useState<null | HTMLElement>(null)
  const [userAnchorEl, setUserAnchorEl] = useState<null | HTMLElement>(null)

  const [searchValue, setSearchValue] = useState<string>('')

  const navigate = useNavigate()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setTenantsAnchorEl(event.currentTarget)
  }

  const handleMenuClose = (reason: string) => {
    if (reason === 'backdropClick') setTenantsAnchorEl(null)
  }

  const handleMenuClick = (tenant: ITenantService) => {
    dispatch(setActiveTenant(tenant))
    setSearchValue('')
    setTenantsAnchorEl(null)
  }

  const handleClick = () => navigate(-1)

  const buildBreadcrumbsByUrl = (paths: string[]) => {
    const parsedPaths = _.includes(paths, ROUTES.ADMIN) ? _.filter(paths, (path) => path !== ROUTES.ADMIN) : paths
    return (
      <>
        {parsedPaths.length > 1 && (
          <Box onClick={handleClick} sx={{ color: Colors.dark, transform: 'rotate(90deg)', marginRight: '10px' }}>
            <ArrowDownIcon />
          </Box>
        )}
        {_.map(parsedPaths, (path, index) => (
          <Fragment key={index}>
            {index !== 0 && <StyledSlashSymbol>/</StyledSlashSymbol>}
            <StyledLinkButton
              disabled={index === parsedPaths.length - 1}
              onClick={() => navigate(paths.slice(0, index + 1).join(''))}
            >
              {ROUTES_TITLES[path]}
            </StyledLinkButton>
          </Fragment>
        ))}
      </>
    )
  }

  const buildCustomBreadcrumbs = (paths: string[]) => {
    const [path] = paths

    const handleClick = () => {
      dispatch(setActiveUser(null))
    }

    switch (path) {
      case ROUTES.MICROSOFT:
        return (
          <BreadcrumbTemplate
            onClickHandle={handleClick}
            paths={paths}
            endPointTitle={activeSubscription?.displayName}
          />
        )
      case ROUTES.ADMIN:
        return (
          <BreadcrumbTemplate
            onClickHandle={handleClick}
            paths={_.filter(paths, (path) => path !== ROUTES.ADMIN)}
            endPointTitle={CUSTOM_TITLES.EDIT_USER}
          />
        )
      default:
        return <></>
    }
  }

  const filteredTenants = _.filter(tenants, (tenant) => tenant.domain.toLowerCase().includes(searchValue.toLowerCase()))

  return (
    <StyledAppBar elevation={0} position='fixed' open={isNavBarOpen}>
      <StyledHeaderContainer>
        <Breadcrumbs>
          {isRedirectWithoutRouting ? buildCustomBreadcrumbs(paths) : buildBreadcrumbsByUrl(paths)}
        </Breadcrumbs>
        <Box sx={{ display: 'flex' }}>
          {!_.includes(paths, ROUTES.ADMIN) ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {loading ? (
                <Box sx={{ width: '40px', display: 'flex', justifyContent: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StyledTenantName sx={{ color: Colors.dark }}>{activeTenant?.domain}</StyledTenantName>
                  <StyledArrowIconContainer
                    open={!!tenantsAnchorEl}
                    sx={{ display: 'flex', alignItems: 'center', margin: '0 10px' }}
                    onClick={handleMenu}
                  >
                    <ArrowSelectIcon />
                  </StyledArrowIconContainer>
                </Box>
              )}
              <Menu
                anchorEl={tenantsAnchorEl}
                open={Boolean(tenantsAnchorEl)}
                onClose={(_, reason: string) => handleMenuClose(reason)}
                PaperProps={{
                  style: {
                    transform: 'translateX(-20%) translateY(15px)',
                    padding: '15px',
                    width: '330px',
                    maxHeight: '332px',
                    overflow: 'hidden'
                  },
                  elevation: 5
                }}
                MenuListProps={{ style: { display: 'flex', flexDirection: 'column', gap: '15px', maxHeight: '300px' } }}
              >
                <Search
                  value={searchValue}
                  handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchValue(e.target.value)
                  }}
                />
                <Box sx={{ overflow: 'auto' }}>
                  {_.map(filteredTenants, (tenant) => (
                    <StyledMenuItem
                      selected={tenant.domain === activeTenant?.domain}
                      onClick={() => handleMenuClick(tenant)}
                      key={tenant.id}
                    >
                      <StyledMenuItemContainer>
                        <Box sx={{ width: '80%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{tenant.domain}</Box>
                        {activeTenant?.domain === tenant.domain && <CheckIcon />}
                      </StyledMenuItemContainer>
                    </StyledMenuItem>
                  ))}
                </Box>
              </Menu>
            </Box>
          ) : null}

          <StyledIconContainer
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setUserAnchorEl(event.currentTarget)
            }}
          >
            {accountPreview}
          </StyledIconContainer>
          <Menu
            anchorEl={userAnchorEl}
            open={Boolean(userAnchorEl)}
            onClose={() => setUserAnchorEl(null)}
            PaperProps={{ style: { width: '200px', transform: 'translateY(5px)' } }}
          >
            <StyledMenuItem
              sx={{ padding: '20px' }}
              onClick={() => {
                instance.logoutRedirect({
                  account: instance.getActiveAccount()
                })
                setUserAnchorEl(null)
              }}
            >
              <LogoutIcon />
              <Typography sx={{ marginLeft: '10px' }}>Log out</Typography>
            </StyledMenuItem>
          </Menu>
        </Box>
      </StyledHeaderContainer>
    </StyledAppBar>
  )
}

export default Header
