import React from 'react'
import { Drawer, List, Box, styled, CSSObject, Theme } from '@mui/material'
import { Link } from 'react-router-dom'
import _ from 'lodash'

import NavbarItem from './navbarItem/navbarItem.component'
import { USER_NAV_ITEMS, NAV_SETTINGS, ADMIN_NAV_ITEMS } from '../../constants/navbar.constant'
import ICONS from '../../constants/icons.constant'
import { Colors } from '../../constants/appearance.constant'
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook'
import { toggleNavBar } from '../../state/features/ui.feature'
import { usePathname } from '../../hooks/usePathname.hook'
import { ROUTES } from '../../constants/routes.constant'

const { MainLogoIcon, MenuCloseIcon, MenuIcon } = ICONS

const navBarTransitions = (open: boolean | undefined, theme: Theme): CSSObject => ({
  width: open ? NAV_SETTINGS.OPENED_WIDTH : NAV_SETTINGS.CLOSED_WIDTH,
  overflowX: 'hidden',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration[open ? 'enteringScreen' : 'leavingScreen']
  })
})

const StyledDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: NAV_SETTINGS.OPENED_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...navBarTransitions(open, theme),
  '& .MuiDrawer-paper': navBarTransitions(open, theme)
}))

const ListWrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const StyledList = styled(List)({
  '& a': {
    textDecoration: 'none',
    '&:hover': {
      color: Colors.dark
    }
  }
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '0 20px',
  ...theme.mixins.toolbar,
  [theme.breakpoints.down('sm')]: {
    minHeight: '64px'
  }
}))

const Navbar = () => {
  const { isNavBarOpen } = useAppSelector((state) => state.ui)
  const dispatch = useAppDispatch()
  const paths = usePathname()

  const handleMenuIcon = () => {
    dispatch(toggleNavBar())
  }

  const navBarHeaderRender = (isOpen: boolean) =>
    isOpen ? (
      <>
        <div onClick={handleMenuIcon}>
          <MenuCloseIcon />
        </div>
        <MainLogoIcon />
      </>
    ) : (
      <div onClick={handleMenuIcon}>
        <MenuIcon />
      </div>
    )

  return (
    <StyledDrawer
      PaperProps={{
        sx: {
          backgroundImage: Colors.gradient,
          borderRadius: '0'
        }
      }}
      variant='permanent'
      open={isNavBarOpen}
    >
      <DrawerHeader>{navBarHeaderRender(isNavBarOpen)}</DrawerHeader>
      <ListWrapper>
        <StyledList>
          {_.includes(paths, ROUTES.ADMIN)
            ? ADMIN_NAV_ITEMS.map((item, index) => (
                <Link key={index} to={item.PATH}>
                  <NavbarItem selected={_.includes(_.join(paths, ''), item.PATH)} title={item.TITLE}>
                    {item.ICON}
                  </NavbarItem>
                </Link>
              ))
            : USER_NAV_ITEMS.map((item, index) => (
                <Link key={index} to={item.PATH}>
                  <NavbarItem selected={_.includes(_.join(paths, ''), item.PATH)} title={item.TITLE}>
                    {item.ICON}
                  </NavbarItem>
                </Link>
              ))}
        </StyledList>
      </ListWrapper>
    </StyledDrawer>
  )
}

export default Navbar
