import React from 'react'

import ICONS from './icons.constant'
import { ROUTES, ROUTES_TITLES } from './routes.constant'

export const NAV_SETTINGS = {
  OPENED_WIDTH: 240,
  CLOSED_WIDTH: 60
}

const { OverviewIcon, Microsoft365Icon, UserIcon, LogsIcon, CatalogIcon } = ICONS

export const USER_NAV_ITEMS = Object.freeze([
  {
    TITLE: ROUTES_TITLES[ROUTES.OVERVIEW],
    PATH: ROUTES.OVERVIEW,
    ICON: <OverviewIcon />
  },
  {
    TITLE: ROUTES_TITLES[ROUTES.MICROSOFT],
    PATH: ROUTES.MICROSOFT,
    ICON: <Microsoft365Icon />
  }
])

export const ADMIN_NAV_ITEMS = Object.freeze([
  {
    TITLE: ROUTES_TITLES[ROUTES.USERS],
    PATH: ROUTES.ADMIN + ROUTES.USERS,
    ICON: <UserIcon />
  },
  {
    TITLE: ROUTES_TITLES[ROUTES.LOGS],
    PATH: ROUTES.ADMIN + ROUTES.LOGS,
    ICON: <LogsIcon />
  },
  {
    TITLE: ROUTES_TITLES[ROUTES.CATALOG],
    PATH: ROUTES.ADMIN + ROUTES.CATALOG,
    ICON: <CatalogIcon />
  }
])
