export const ROUTES = {
  OVERVIEW: '/overview',
  PROFILE: '/profile',
  MICROSOFT: '/microsoft365',
  AZURE: '/azure',
  SUPPORT: '/support',
  CONTACT: '/contact',
  ADD_LICENCES: '/add-licenses',
  USERS: '/users',
  ADD_USER: '/add-user',
  ADMIN: '/admin',
  LOGS: '/logs',
  CATALOG: '/catalog'
}

export const ROUTES_TITLES = {
  [ROUTES.OVERVIEW]: 'Overview',
  [ROUTES.MICROSOFT]: 'Microsoft 365',
  [ROUTES.AZURE]: 'Azure Service View',
  [ROUTES.SUPPORT]: 'UbTech Support',
  [ROUTES.ADD_LICENCES]: 'Add licenses',
  [ROUTES.USERS]: 'Users management',
  [ROUTES.ADD_USER]: 'Add new user',
  [ROUTES.ADMIN]: 'Admin',
  [ROUTES.LOGS]: 'Logs',
  [ROUTES.CATALOG]: 'Catalog'
}

export const CUSTOM_TITLES = {
  EDIT_USER: 'Edit user'
}
