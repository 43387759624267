import React, { useEffect } from 'react'
import { Box, Checkbox, CircularProgress, FormControlLabel, FormGroup, Typography } from '@mui/material'
import _ from 'lodash'
import { CSVLink } from 'react-csv'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'

import ICONS from '../constants/icons.constant'
import Button from '../components/button/button.component'
import { ButtonAppearance, Colors } from '../constants/appearance.constant'
import Table from '../components/table/table.component'
import { useAppDispatch, useAppSelector } from '../hooks/redux.hook'
import { fetchSubscriptionsByTenantId, ISubscription, setActiveSubscription } from '../state/features/service.feature'
import { CellType, PERMISSIONS, ROLE_TYPES, SERVICES, TERM_DURATION } from '../constants/global.constant'
import EditSubscriptionModal from '../components/editSubscriptionModal/editSubscriptionModal.component'
import { postUsageReport, setActiveService } from '../state/features/user.feature'
import { ROUTES } from '../constants/routes.constant'
import { StyledGridContainer } from '../constants/styles.constant'

const { AddIcon, ReportIcon, EmptyCatalogIcon } = ICONS

const columns = [
  { displayName: 'License title', key: 'displayName', cellType: CellType.text, width: '30%' },
  { displayName: 'License id', key: 'id', cellType: CellType.text, width: '30%' },
  { displayName: 'Licenses', key: 'licenses', cellType: CellType.text, width: '10%' },
  { displayName: 'Commitment End Date', key: 'flatCommitmentEndDate', cellType: CellType.text, width: '10%' },
  { displayName: 'Billing Cycle', key: 'flatBillingCycle', cellType: CellType.text, width: '10%' },
  { displayName: 'Term Duration', key: 'flatTermDuration', cellType: CellType.text, width: '10%' }
]

const csvColumns = _.map(columns, (column) => ({ label: column.displayName, key: column.key }))

const Microsoft365Page = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { activeTenant, user, activeService } = useAppSelector((state) => state.user)
  const { subscriptions, activeSubscription, loading, error } = useAppSelector((state) => state.service)

  const currentService = activeTenant?.services?.find((service) => service.displayName === SERVICES.microsoft365)

  useEffect(() => {
    activeTenant && dispatch(fetchSubscriptionsByTenantId(activeTenant.msTenantId))
    dispatch(setActiveSubscription(null))
  }, [activeTenant])

  useEffect(() => {
    currentService && dispatch(setActiveService(currentService))
  }, [activeTenant])

  const handleEditSubscriptionActionClick = (subscription: ISubscription) => {
    dispatch(setActiveSubscription(subscription))
  }

  const handleMonthlyCheckboxChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    activeTenant &&
      user &&
      dispatch(postUsageReport({ tenantId: activeTenant.id, userId: user.id, emailSubscription: checked }))
  }

  const parsedSubscritpions = _.map(subscriptions, (subscription) => ({
    ...subscription,
    flatCommitmentEndDate: format(new Date(subscription.commitmentEndDate), 'dd/MM/yyyy'),
    flatBillingCycle: _.capitalize(subscription.billingCycle),
    flatTermDuration: TERM_DURATION[subscription.termDuration as keyof typeof TERM_DURATION] || 'None'
  }))

  const csvReport = {
    data: parsedSubscritpions,
    headers: csvColumns,
    filename: 'microsoft365_table_report.csv'
  }

  if (error)
    return (
      <StyledGridContainer>
        <Typography>Something went wrong</Typography>
      </StyledGridContainer>
    )

  if (_.isEmpty(subscriptions))
    return (
      <>
        <StyledGridContainer>
          {loading ? (
            <CircularProgress />
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
              <EmptyCatalogIcon />
              <Box sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontSize: '21px', fontWeight: 300, marginBottom: '8px' }}>
                  You’ve not added any licenses yet
                </Typography>
                <Typography sx={{ color: Colors.blackLight }}>Click the button bellow to add new</Typography>
              </Box>
              <Button
                appearance={ButtonAppearance.primary}
                onClick={() => navigate(`${ROUTES.MICROSOFT}${ROUTES.ADD_LICENCES}`)}
              >
                <AddIcon />
                Add licenses
              </Button>
            </Box>
          )}
        </StyledGridContainer>
      </>
    )

  return (
    <>
      <Box sx={{ display: 'flex', marginBottom: '20px', gap: '20px' }}>
        {currentService?.permission === PERMISSIONS.write && (
          <Button
            sx={{ width: '160px' }}
            appearance={ButtonAppearance.primary}
            onClick={() => navigate(`${ROUTES.MICROSOFT}${ROUTES.ADD_LICENCES}`)}
          >
            <AddIcon />
            Add licenses
          </Button>
        )}
        <CSVLink style={{ textDecoration: 'none' }} {...csvReport}>
          <Button appearance={ButtonAppearance.secondary}>
            <ReportIcon />
            <Box sx={{ marginLeft: '8px' }}>Usage report</Box>
          </Button>
        </CSVLink>
      </Box>

      {user?.role === ROLE_TYPES.user && (
        <Box sx={{ padding: '20px 0', width: 'fit-content' }}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox />}
              label='Recieve a monthly report for the consumed licenses in the organization'
              onChange={handleMonthlyCheckboxChange}
              checked={activeService?.emailSubscrition}
            />
          </FormGroup>
        </Box>
      )}

      <Box sx={{ height: '90%', overflow: 'auto' }}>
        <Table
          permission={currentService?.permission}
          columns={columns}
          items={parsedSubscritpions}
          onClickAction={handleEditSubscriptionActionClick}
        />
      </Box>
      {activeSubscription && <EditSubscriptionModal activeSubscription={activeSubscription} />}
    </>
  )
}

export default Microsoft365Page
