import React, { useEffect, useState } from 'react'
import { Box, SelectChangeEvent } from '@mui/material'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { useNavigate } from 'react-router-dom'

import { ButtonAppearance } from '../../constants/appearance.constant'
import Button from '../../components/button/button.component'
import { EMAIL_REGEX, Roles, ROLE_TYPES } from '../../constants/global.constant'
import AddSalesRepTenantBox from '../../components/addSalesRepTenantBox/addSalesRepTenantBox.component'
import AddNewUserTenantBox from '../../components/addUserTenantBox/addUserTenantBox.component'
import AddUserGeneralBox from '../../components/addUserGeneralBox/addUserGeneralBox.component'
import { IService, ITenant } from '../../state/features/user.feature'
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook'
import {
  editUser,
  fetchAllServices,
  fetchAllTenants,
  inviteUser,
  setActiveUser,
  setIsUserCreationSuccess
} from '../../state/features/admin.feature'
import { setIsRedirectWithoutRouting } from '../../state/features/global.feature'

export interface IUserTenant {
  _id: string
  domain: string
  emailSubscription: boolean
  services: Record<string, string>[]
}

const UsersManagementForm = () => {
  const dispatch = useAppDispatch()
  const { activeUser, tenants, services, loading, isUserCreationSuccess } = useAppSelector((state) => state.admin)

  useEffect(() => {
    dispatch(fetchAllServices())
    dispatch(fetchAllTenants())
  }, [])

  useEffect(() => {
    if (isUserCreationSuccess) onExitIntent()
    return () => {
      dispatch(setIsUserCreationSuccess(false))
    }
  }, [isUserCreationSuccess])

  const navigate = useNavigate()

  const [email, setEmail] = useState<string>(activeUser?.email || '')
  const [role, setRole] = useState<string>(activeUser?.role || 'user')
  const [errorFlag, setErrorFlag] = useState(false)

  const userInintialState =
    activeUser?.role === ROLE_TYPES.user
      ? activeUser.tenants.map((tenant) => {
          return {
            _id: uuid(),
            domain: tenant.domain,
            emailSubscription: tenant.emailSubscription,
            services: tenant.services.map((service) => {
              return {
                _id: uuid(),
                name: service.name,
                permission: service.permission
              }
            })
          }
        })
      : [
          {
            _id: uuid(),
            domain: '',
            emailSubscription: false,
            services: [{ _id: uuid(), name: 'microsoft365', permission: 'read' }]
          }
        ]

  const salesRepInitialState =
    activeUser?.role === ROLE_TYPES.salesRep ? activeUser.tenants.map((tenant) => tenant.domain) : []

  const [salesRepSelectedTenants, setSalesRepSelectedTenants] = useState<string[]>(salesRepInitialState)
  const [userTenants, setUserTenants] = useState<IUserTenant[]>(userInintialState)

  const onParseAndFilterTenants = (tenants: ITenant[], userTenants: IUserTenant[]) => {
    const parsedRelatedTenants = _.map(tenants, (tenant) => tenant.domain)
    const allSelectedUserTenantsDomains = _.map(userTenants, (userTenant) => userTenant.domain)
    return _.difference(parsedRelatedTenants, allSelectedUserTenantsDomains)
  }

  const onParseServices = (services: IService[]) => {
    return services.map((service) => service.name)
  }

  const isEmailValid = EMAIL_REGEX.test(email)
  const isShowError = (context: any) => _.isEmpty(context) && errorFlag
  const isShowEmailError = (context: any) => (_.isEmpty(context) || !isEmailValid) && errorFlag

  const onExitIntent = () => {
    if (activeUser) {
      dispatch(setActiveUser(null))
      dispatch(setIsRedirectWithoutRouting(false))
    } else {
      navigate(-1)
    }
  }

  // eslint-disable-next-line complexity
  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault()

    if (!isEmailValid) {
      setErrorFlag(true)
      return
    }

    switch (role) {
      case 'admin': {
        dispatch(activeUser ? editUser({ id: activeUser.id, email, role }) : inviteUser({ email, role }))
        break
      }
      case 'user': {
        const isArrValid = userTenants
          .map(({ domain, services }) => Boolean(domain && services.length > 0))
          .every(Boolean)

        if (!isArrValid) {
          setErrorFlag(true)
          return
        }

        const relatedTenants = userTenants.map((userTenant) => {
          const flatTenant = tenants.find((tenant) => tenant.domain === userTenant.domain)
          const flatServices = userTenant.services.map((usService) => {
            const serviceFromDb = services.find((service) => service.name === usService.name)
            return { id: serviceFromDb?.id, permission: usService?.permission }
          })
          return {
            ...flatTenant,
            services: flatServices,
            emailSubscription: userTenant.emailSubscription
          }
        })
        dispatch(
          activeUser
            ? editUser({ id: activeUser.id, email, role, tenants: relatedTenants })
            : inviteUser({ email, role, tenants: relatedTenants })
        )
        break
      }
      case 'salesRep': {
        if (salesRepSelectedTenants.length === 0) {
          setErrorFlag(true)
          return
        }

        if (salesRepSelectedTenants.length) {
          const relatedTenants = tenants.filter((tenant) => {
            if (salesRepSelectedTenants.includes(tenant.domain)) {
              return tenant
            }
          })
          dispatch(
            activeUser
              ? editUser({ id: activeUser.id, email, role, tenants: relatedTenants })
              : inviteUser({ email, role, tenants: relatedTenants })
          )
        }
        break
      }
    }

    if (activeUser && !loading) {
      dispatch(setActiveUser(null))
      dispatch(setIsRedirectWithoutRouting(false))
    }
    setEmail('')
    setSalesRepSelectedTenants([])
    setUserTenants([
      {
        _id: uuid(),
        domain: '',
        emailSubscription: false,
        services: [{ _id: uuid(), name: 'microsoft365', permission: 'read' }]
      }
    ])
    setErrorFlag(false)
  }

  const handleRoleChange = (event: SelectChangeEvent) => {
    setRole(event.target.value)
    setErrorFlag(false)
  }

  const handleCreateUserTenant = () => {
    setUserTenants([
      ...userTenants,
      {
        _id: uuid(),
        domain: '',
        emailSubscription: false,
        services: [{ _id: uuid(), name: 'microsoft365', permission: 'read' }]
      }
    ])
  }

  const handleRemoveUserTenant = (userTenantId: string) => {
    if (userTenants.length !== 1) setUserTenants(userTenants.filter((userTenant) => userTenant._id !== userTenantId))
  }

  const handleUserRelatedTenantsChange = (value: string, userTenantId: string) => {
    const newUserTenants = userTenants.map((userTenant) => {
      if (userTenant._id === userTenantId) return { ...userTenant, domain: value }
      return userTenant
    })
    setUserTenants(newUserTenants)
  }

  const handlePermissonChange = (value: string, userTenantId: string, serviceId: string) => {
    const newUserTenants = userTenants.map((userTenant) => {
      if (userTenant._id === userTenantId)
        return {
          ...userTenant,
          services: userTenant.services.map((service) =>
            service._id === serviceId ? { ...service, permission: value } : service
          )
        }
      return userTenant
    })
    setUserTenants(newUserTenants)
  }

  const handleServicesChange = (value: string, userTenantId: string, serviceId: string) => {
    const newUserTenants = userTenants.map((userTenant) => {
      if (userTenant._id === userTenantId)
        return {
          ...userTenant,
          services: userTenant.services.map((service) =>
            service._id === serviceId ? { ...service, name: value } : service
          )
        }
      return userTenant
    })
    setUserTenants(newUserTenants)
  }

  const handleSalesRepSelectedTenantsChange = (value: string[]) => {
    setSalesRepSelectedTenants(value)
  }

  const handleCancelBtn = () => {
    onExitIntent()
  }

  const hanldeTenantEmailSubscriprionChange = (checked: boolean, userTenantId: string) => {
    const newUserTenants = _.map(userTenants, (userTenant) =>
      userTenant._id === userTenantId ? { ...userTenant, emailSubscription: checked } : userTenant
    )
    setUserTenants(newUserTenants)
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
      <Box component='form' onSubmit={handleSubmit} sx={{ width: '600px', display: 'flex', flexDirection: 'column' }}>
        <AddUserGeneralBox
          isActiveUser={Boolean(activeUser)}
          role={role as Roles}
          email={email}
          setEmail={setEmail}
          onRoleChange={handleRoleChange}
          isShowEmailError={isShowEmailError}
        />

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px', overflow: 'overlay' }}>
          {role === 'salesRep' && (
            <AddSalesRepTenantBox
              relatedTenants={tenants}
              selectedTenants={salesRepSelectedTenants}
              onSalesRepSelectedTenantsChange={handleSalesRepSelectedTenantsChange}
              isShowError={isShowError}
            />
          )}
          {role === 'user' &&
            userTenants.map((userTenant: IUserTenant) => (
              <AddNewUserTenantBox
                userTenantId={userTenant._id}
                key={userTenant._id}
                userTenant={userTenant}
                onCreateUserTenant={handleCreateUserTenant}
                onRemoveUserTenant={handleRemoveUserTenant}
                relatedTenants={onParseAndFilterTenants(tenants, userTenants)}
                onPermissionChange={handlePermissonChange}
                onUserRelatedTenantsChange={handleUserRelatedTenantsChange}
                onServicesChange={handleServicesChange}
                isShowError={isShowError}
                userTenantLength={userTenants.length}
                relatedServices={onParseServices(services)}
                onTenantEmailSubscriprionChange={hanldeTenantEmailSubscriprionChange}
              />
            ))}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px', flexBasis: '36px' }}>
          <Box sx={{ width: '148px', marginRight: '20px' }}>
            <Button onClick={handleCancelBtn} appearance={ButtonAppearance.secondary}>
              Cancel
            </Button>
          </Box>
          <Box sx={{ width: '148px' }}>
            <Button loading={loading && !_.isEmpty(tenants) && !_.isEmpty(services)} type='submit'>
              {activeUser ? 'Edit' : 'Create'}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default UsersManagementForm
