import React from 'react'
import { Box } from '@mui/material'

import UsersManagementForm from '../components/usersManagementForm/usersManagementForm.component'

const AddNewUserPage = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}>
      <UsersManagementForm />
    </Box>
  )
}

export default AddNewUserPage
