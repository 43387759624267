import React, { useState } from 'react'
import { Checkbox, Box, styled, Typography, Tooltip } from '@mui/material'
import _ from 'lodash'

import ICONS from '../../constants/icons.constant'
import { Colors } from '../../constants/appearance.constant'
import { CellType, PERMISSIONS } from '../../constants/global.constant'
import Input from '../input/input.component'

const { EditIcon, ArrowDownIcon } = ICONS

const StyledTable = styled('table')({
  borderCollapse: 'collapse',
  tableLayout: 'fixed',
  width: '100%'
})

interface IStyledTableHeaderCellProps {
  width?: string
}

const StyledTableHeaderCell = styled('th')(({ width }: IStyledTableHeaderCellProps) => ({
  width,
  'text-align': 'left',
  borderBottom: `1px solid ${Colors.blueOpacityMedium}`,
  padding: '20px 15px',
  '&:hover': {
    '& div': {
      opacity: 1
    }
  }
}))

const StyledTableHeader = styled('thead')({
  borderBottom: `1px solid ${Colors.blueOpacityMedium}`,
  backgroundColor: Colors.white,
  position: 'sticky',
  top: 0,
  zIndex: 1
})

const StyledHeaderText = styled(Typography)({
  color: Colors.grey,
  letterSpacing: '1px',
  fontSize: '12px',
  fontWeight: '500',
  textTransform: 'uppercase',
  marginRight: '8px'
})

interface IStyledTableRowProps {
  selected?: boolean
  isExpired?: boolean
}

const StyledTableRow = styled('tr', {
  shouldForwardProp: (prop) => prop !== 'isExpired'
})(({ selected, isExpired }: IStyledTableRowProps) => {
  if (selected) {
    return {
      borderBottom: '1px solid',
      borderColor: Colors.blueOpacityMedium,
      backgroundColor: Colors.blueOpacityLow,
      color: isExpired ? Colors.red : Colors.bluePrimary,
      '&:hover': {
        '& div': {
          opacity: 1
        }
      }
    }
  }
  return {
    borderBottom: '1px solid',
    borderColor: Colors.blueOpacityMedium,
    '& td': {
      color: isExpired ? Colors.red : selected ? Colors.bluePrimary : Colors.dark
    },
    '&:hover': {
      backgroundColor: Colors.blueOpacityLow,
      '& td': {
        color: isExpired ? Colors.red : Colors.bluePrimary
      },
      '& div': {
        opacity: 1
      }
    }
  }
})

const StyledTableCell = styled('td')({
  padding: '15px',
  position: 'relative'
})

const StyledCheckboxCell = styled('th')({
  width: '5%',
  padding: 0
})

interface IStyledArrowBoxProps {
  active: boolean
  direction: string
}

const StyledArrowBox = styled(Box)(({ active, direction }: IStyledArrowBoxProps) => ({
  opacity: active ? 1 : 0,
  color: Colors.purplePrimary,
  transform: direction === 'asc' ? 'rotate(180deg)' : '',
  transition: '0.2s'
}))

interface IColumn {
  [key: string]: any
}

interface IRow {
  [key: string]: any
}

type Order = 'asc' | 'desc'

const descendingComparator = <T,>(a: T, b: T, orderBy: keyof T) => {
  if (b[orderBy] < a[orderBy]) {
    return -1
  }
  if (b[orderBy] > a[orderBy]) {
    return 1
  }
  return 0
}

const getComparator = <Key extends keyof any>(
  order: Order,
  orderBy: Key
): ((a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy)
}

interface ITableProps {
  columns: IColumn[]
  items: IRow[]
  checkboxSelection?: boolean
  countValues?: Record<string, number>
  onChangeHandle?: any
  onClickAction?: any
  onHandleSelected?: (id: any) => void
  onHandleSelectedAll?: (event: React.ChangeEvent<HTMLInputElement>) => void
  selected?: string[] | number[]
  permission?: string
  initOrderBy?: string
}

const Table = ({
  permission = 'read',
  columns,
  items,
  checkboxSelection,
  onChangeHandle,
  selected,
  onHandleSelected,
  onHandleSelectedAll,
  countValues,
  onClickAction,
  initOrderBy
}: ITableProps) => {
  const [order, setOrder] = useState<Order>('asc')
  const [orderBy, setOrderBy] = useState<keyof IRow>(initOrderBy || 'displayName')

  const handleRequestSort = (value: string) => {
    const isAsc = orderBy === value && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(value)
  }

  const handleCountChange = (id: string, event: React.ChangeEvent<HTMLInputElement>) => {
    onChangeHandle(id, event.target.value)
  }

  const sortedItems = [...items].sort(getComparator(order, orderBy))

  return (
    <StyledTable>
      <StyledTableHeader>
        <tr>
          {checkboxSelection && selected && onHandleSelectedAll && (
            <StyledCheckboxCell>
              <Checkbox
                indeterminate={selected.length > 0 && selected.length < items.length}
                checked={items.length > 0 && selected?.length === items.length}
                onChange={onHandleSelectedAll}
              />
            </StyledCheckboxCell>
          )}
          {columns.map((columnItem) => (
            <StyledTableHeaderCell width={columnItem.width} key={columnItem.key}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StyledHeaderText>{columnItem.displayName}</StyledHeaderText>
                <StyledArrowBox
                  active={columnItem.key === orderBy}
                  direction={orderBy === columnItem.key ? order : 'asc'}
                  onClick={() => handleRequestSort(columnItem.key)}
                >
                  <ArrowDownIcon />
                </StyledArrowBox>
              </Box>
            </StyledTableHeaderCell>
          ))}
        </tr>
      </StyledTableHeader>
      <tbody>
        {sortedItems.map((sortedItem) => {
          const isItemSelected = _.includes(selected, sortedItem.id)
          return (
            <StyledTableRow
              selected={checkboxSelection && isItemSelected}
              key={sortedItem.id}
              isExpired={sortedItem.isExpired}
            >
              {checkboxSelection && onHandleSelected && selected && (
                <StyledCheckboxCell>
                  <Checkbox checked={isItemSelected} onClick={() => onHandleSelected(sortedItem.id)} />
                </StyledCheckboxCell>
              )}
              {columns.map((columnItem, colIndex) => {
                if (columnItem.cellType === CellType.input && countValues)
                  return (
                    <StyledTableCell key={columnItem.key}>
                      <Input
                        sx={{
                          width: '70px',
                          padding: '8px',
                          textAlign: 'center',
                          color: Number(countValues[sortedItem.id]) === 0 ? Colors.grey : Colors.dark
                        }}
                        value={countValues[sortedItem.id]}
                        handleChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          handleCountChange(sortedItem.id, event)
                        }
                        onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
                        other={{ type: 'text' }}
                        maxLength={5}
                      />
                    </StyledTableCell>
                  )
                if (columnItem.cellType === CellType.text)
                  return (
                    <StyledTableCell key={columnItem.key}>
                      <Tooltip title={sortedItem[`${columnItem.key}`] || ''}>
                        <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                          {sortedItem[`${columnItem.key}`]}
                        </Box>
                      </Tooltip>
                      {colIndex === columns.length - 1 && permission === PERMISSIONS.write && (
                        <Box
                          sx={{ position: 'absolute', right: '10px', bottom: '7px' }}
                          onClick={() => onClickAction?.(sortedItem)}
                        >
                          <EditIcon />
                        </Box>
                      )}
                    </StyledTableCell>
                  )
              })}
            </StyledTableRow>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

export default Table
