import React from 'react'
import { styled } from '@mui/material/styles'

const Wrapper = styled('div')`
  cursor: pointer;
`

const MenuIcon = () => (
  <Wrapper>
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path d='M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z' fill='#FFF' fillRule='nonzero' />
      </g>
    </svg>
  </Wrapper>
)

export default MenuIcon
