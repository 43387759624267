import React, { useEffect, useState } from 'react'
import { Box, styled, Typography, Button as MuiBtn, CircularProgress } from '@mui/material'
import _ from 'lodash'

import {
  StyledCloseButton,
  StyledGridContainer,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalTitle
} from '../../constants/styles.constant'
import Modal from '../modal/modal.component'
import Table from '../table/table.component'
import { Colors } from '../../constants/appearance.constant'
import Search from '../search/search.component'
import { CellType } from '../../constants/global.constant'
import Button from '../button/button.component'
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook'
import { addToAdminSelectedSubscriptionsCatalog, setIsCatalogModalOpen } from '../../state/features/admin.feature'

const StyledModalBody = styled(Box)({
  borderTop: `1px solid ${Colors.blueOpacityHigh}`,
  borderBottom: `1px solid ${Colors.blueOpacityHigh}`,
  padding: '20px',
  height: '78%'
})

const columns = [
  { displayName: 'License title', key: 'name', cellType: CellType.text, width: '50%' },
  { displayName: 'License id', key: 'id', cellType: CellType.text, width: '50%' }
]

// eslint-disable-next-line complexity
const AddCatalogSubscriptionsModal = () => {
  const dispatch = useAppDispatch()

  const [searchValue, setSearchValue] = useState('')

  const [selected, setSelected] = useState<string[]>([])

  const {
    adminAllSubscriptionsCatalog,
    adminSelectedSubscriptionsCatalog,
    isCatalogModalOpen,
    loading,
    catalogModalError
  } = useAppSelector((state) => state.admin)

  useEffect(() => {
    return () => {
      dispatch(setIsCatalogModalOpen(false))
    }
  }, [])

  const handleSubmit = () => {
    const formData = _.filter(adminAllSubscriptionsCatalog, (subs) => _.includes(selected, subs.id))
    dispatch(addToAdminSelectedSubscriptionsCatalog(formData))
  }

  const handleSelect = (id: string) => {
    const selectedIndex = selected.indexOf(id)
    if (selectedIndex === -1) return setSelected([...selected, id])
    const newSelected = selected.filter((_, index) => index !== selectedIndex)
    setSelected(newSelected)
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? setSelected(adminAllSubscriptionsCatalog.map((item) => item.id as string)) : setSelected([])
  }

  const handleClose = () => {
    dispatch(setIsCatalogModalOpen(false))
    setSelected([])
  }

  const filterdAdminSubscriptionsCatalogWithoutSelected = _.chain(adminAllSubscriptionsCatalog)
    .differenceWith(adminSelectedSubscriptionsCatalog, ({ id }, { msSubscriptionId }) => msSubscriptionId === id)
    .filter((item) => _.includes(item.name.toLowerCase(), searchValue.toLowerCase()))
    .value()

  const modalBodyRender = () => (
    <StyledModalBody>
      {loading && (
        <StyledGridContainer>
          <CircularProgress size={25} />
        </StyledGridContainer>
      )}
      {catalogModalError && <StyledGridContainer>Something went wrong</StyledGridContainer>}
      {_.isEmpty(adminAllSubscriptionsCatalog) && <StyledGridContainer>No licenses to choose</StyledGridContainer>}
      {!catalogModalError && !loading && !_.isEmpty(adminAllSubscriptionsCatalog) && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingBottom: '20px' }}>
            <Typography>Please select licenses from the catalog below</Typography>
            <Search
              sx={{ width: '300px' }}
              value={searchValue}
              handleChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
            />
          </Box>
          <Box sx={{ height: '90%', overflow: 'auto' }}>
            <Table
              columns={columns}
              items={filterdAdminSubscriptionsCatalogWithoutSelected}
              checkboxSelection
              selected={selected}
              onHandleSelected={handleSelect}
              onHandleSelectedAll={handleSelectAll}
            />
          </Box>
        </>
      )}
    </StyledModalBody>
  )

  return (
    <Modal onClose={handleClose} open={isCatalogModalOpen} sx={{ width: '804px', height: '688px', padding: 0 }}>
      <StyledModalHeader sx={{ padding: '20px' }}>
        <StyledModalTitle>Catalog</StyledModalTitle>
        <StyledCloseButton onClick={handleClose}>&times;</StyledCloseButton>
      </StyledModalHeader>
      {modalBodyRender()}
      <StyledModalFooter sx={{ padding: '20px' }}>
        <MuiBtn variant='text' sx={{ width: '88px' }} onClick={handleClose}>
          Cancel
        </MuiBtn>
        <Button
          disabled={loading || _.isEmpty(adminAllSubscriptionsCatalog) || _.isEmpty(selected)}
          onClick={handleSubmit}
          sx={{ width: '88px' }}
        >
          Add
        </Button>
      </StyledModalFooter>
    </Modal>
  )
}

export default AddCatalogSubscriptionsModal
