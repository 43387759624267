import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import _ from 'lodash'

import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook'
import Modal from '../modal/modal.component'
import { ButtonAppearance, ButtonShape } from '../../constants/appearance.constant'
import Button from '../button/button.component'
import { ISubscription, postEditSubscriptionData, setActiveSubscription } from '../../state/features/service.feature'
import {
  StyledCloseButton,
  StyledInputLabel,
  StyledModalHeader,
  StyledModalTitle
} from '../../constants/styles.constant'
import Input from '../input/input.component'

interface IEditSubscriptionModalProps {
  activeSubscription: ISubscription
}

const EditSubscriptionModal = ({ activeSubscription }: IEditSubscriptionModalProps) => {
  const dispatch = useAppDispatch()

  const { loading } = useAppSelector((state) => state.service)
  const { activeTenant } = useAppSelector((state) => state.user)

  const [inputValue, setInputValue] = useState(activeSubscription.licenses)

  const handleClose = () => {
    dispatch(setActiveSubscription(null))
  }

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    !_.isNaN(Number(value)) && setInputValue(Number(value.slice(0, 5)))
  }

  // const isEditAllowed = (date: string) => {
  //   const allowedDateToTimestamp = new Date(date).getTime()
  //   const nowDateToTimestamp = new Date().getTime()
  //   return allowedDateToTimestamp >= nowDateToTimestamp
  // }

  const handleRangeButtons = (event: React.MouseEvent<HTMLButtonElement>) =>
    event.currentTarget.name === 'incrBtn' ? setInputValue(inputValue + 1) : setInputValue(inputValue - 1)

  const handleSubmit = () => {
    activeTenant &&
      dispatch(
        postEditSubscriptionData({
          msSubscriptionId: activeSubscription.id,
          quantity: inputValue,
          msTenantId: activeTenant.msTenantId
        })
      )
  }

  return (
    <Modal
      onClose={handleClose}
      open={!!activeSubscription}
      sx={{ maxWidth: '595px', minHeight: '371px', margin: '0 30px' }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
        <StyledModalHeader>
          <StyledModalTitle>{activeSubscription?.displayName}</StyledModalTitle>
          <StyledCloseButton onClick={handleClose}>&times;</StyledCloseButton>
        </StyledModalHeader>
        <Typography sx={{ marginBottom: '24px', paddingRight: '72px' }}>
          License quantity can only be reduced within 7 days of purchase or renewal of a subscription. Licenses added at
          midterm can also only be reduced within 7 days.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '50px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box>
              <StyledInputLabel sx={{ marginBottom: '20px' }}>Licenses #</StyledInputLabel>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  disabled={inputValue < 2}
                  btnName='decrBtn'
                  onClick={handleRangeButtons}
                  appearance={ButtonAppearance.secondary}
                  shape={ButtonShape.round}
                >
                  <Box sx={{ fontSize: '24px' }}>&#8722;</Box>
                </Button>
                <Box sx={{ maxWidth: '85px', margin: '0 10px' }}>
                  <Input
                    value={inputValue}
                    handleChange={handleChange}
                    onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
                    type='text'
                    sx={{ textAlign: 'center' }}
                    maxLength={5}
                  />
                </Box>
                <Button
                  btnName='incrBtn'
                  onClick={handleRangeButtons}
                  appearance={ButtonAppearance.secondary}
                  shape={ButtonShape.round}
                  disabled={inputValue === 99999}
                >
                  <Box sx={{ fontSize: '24px' }}>&#43;</Box>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '20px' }}>
            <Box sx={{ width: '148px' }}>
              <Button appearance={ButtonAppearance.secondary} onClick={handleClose}>
                Cancel
              </Button>
            </Box>
            <Box sx={{ width: '148px' }}>
              <Button
                onClick={handleSubmit}
                disabled={activeSubscription.licenses === inputValue || inputValue === 0}
                loading={loading}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}

export default EditSubscriptionModal
