import React from 'react'
import { Snackbar, SnackbarOrigin, styled } from '@mui/material'

import { Colors } from '../../constants/appearance.constant'
import { useAppDispatch, useAppSelector } from '../../hooks/redux.hook'
import { toggleToast } from '../../state/features/ui.feature'
import { TOAST_MESSAGES } from '../../constants/ui.constant'

interface IToastProps {
  position?: SnackbarOrigin
  duration?: number
}

interface IStyledStyledSnackbarProps {
  success: boolean
}

const StyledSnackbar = styled(Snackbar, {
  shouldForwardProp: (prop) => prop !== 'success'
})(({ success }: IStyledStyledSnackbarProps) => ({
  borderRadius: '4px',
  '& .MuiSnackbarContent-root': {
    backgroundColor: success ? Colors.dark : Colors.red,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  '& .MuiSnackbarContent-message': {
    fontSize: '13px'
  }
}))

const Toast = ({
  position = {
    vertical: 'bottom',
    horizontal: 'right'
  },
  duration = 3000
}: IToastProps) => {
  const dispatch = useAppDispatch()
  const { toastConfig } = useAppSelector((state) => state.ui)

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'timeout')
      dispatch(toggleToast({ isToastOpen: false, title: TOAST_MESSAGES.empty, success: toastConfig.success }))
  }
  return (
    <StyledSnackbar
      anchorOrigin={position}
      open={toastConfig?.isToastOpen}
      onClose={handleClose}
      autoHideDuration={duration}
      message={toastConfig?.title}
      success={toastConfig.success}
    />
  )
}

export default Toast
