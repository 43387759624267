import React from 'react'
import { styled } from '@mui/material'
interface IBreadcrumbsProps {
  children: React.ReactNode | undefined
}

const StyledBreadcrumb = styled('div')({ display: 'flex', alignItems: 'center', whiteSpace: 'nowrap' })

const Breadcrumbs = ({ children }: IBreadcrumbsProps) => <StyledBreadcrumb>{children}</StyledBreadcrumb>

export default Breadcrumbs
