import React from 'react'
import { CircularProgress, styled } from '@mui/material'

import { Colors, ButtonAppearance, ButtonShape } from '../../constants/appearance.constant'

interface IStyledButtonProps {
  appearance: ButtonAppearance
  radius: string
  shape: ButtonShape
  disabled: boolean
  selected: boolean
}

// eslint-disable-next-line complexity
const StyledButton = styled('button')(({ appearance, shape, radius, disabled, selected }: IStyledButtonProps) => ({
  width: shape === ButtonShape.rect ? '100%' : '36px',
  height: '36px',
  padding: shape === ButtonShape.rect ? '5px 15px' : '',
  fontSize: '15px',
  opacity: disabled ? '0.5' : '1',
  backgroundColor: selected ? Colors.blueOpacityLow : colorSelector(appearance),
  color: selected ? Colors.indigoSecondary : appearance === ButtonAppearance.secondary ? Colors.dark : Colors.white,
  '&:hover': {
    ...(!selected && {
      backgroundColor: colorSelector(appearance),
      opacity: disabled ? '0.5' : '0.8'
    })
  },
  display: 'flex',
  justifyContent: 'center',
  gap: '10px',
  alignItems: 'center',
  border: selected ? `1px solid ${Colors.indigoSecondary}` : 'none',
  cursor: disabled ? 'default' : 'pointer',
  borderRadius: shape === ButtonShape.rect ? radius : '50%',
  transition: '0.2s'
}))

const colorSelector = (appearance: ButtonAppearance) => {
  switch (appearance) {
    case ButtonAppearance.primary: {
      return Colors.purplePrimary
    }
    case ButtonAppearance.secondary: {
      return Colors.greyLight
    }
  }
}

interface IButtonProps {
  children: React.ReactNode | undefined
  type?: 'submit' | 'reset' | 'button'
  appearance?: ButtonAppearance
  shape?: ButtonShape
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  radius?: string
  btnName?: string
  disabled?: boolean
  selected?: boolean
  loading?: boolean
  loaderColor?: string
  sx?: Record<string, any>
  id?: string
}

const Button = ({
  children,
  btnName,
  onClick,
  type = 'button',
  appearance = ButtonAppearance.primary,
  shape = ButtonShape.rect,
  radius = '4px',
  disabled = false,
  selected = false,
  loading = false,
  loaderColor = Colors.white,
  sx,
  id
}: IButtonProps) => (
  <StyledButton
    selected={selected}
    disabled={disabled || loading}
    name={btnName}
    shape={shape}
    radius={radius}
    type={type}
    onClick={onClick}
    appearance={appearance}
    sx={sx}
    id={id}
  >
    {loading ? <CircularProgress size={15} sx={{ color: loaderColor }} /> : children}
  </StyledButton>
)

export default Button
