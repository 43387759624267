import React from 'react'

const SendIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path
        d='M20 3H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h9v-2H4V7l8 5 8-5v5h2V5c0-1.1-.9-2-2-2zm-8 7L4 5h16l-8 5zm7 4 4 4-4 4v-3h-4v-2h4v-3z'
        fill='currentColor'
        fillRule='nonzero'
      />
    </g>
  </svg>
)

export default SendIcon
