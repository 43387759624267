import React from 'react'
import { styled } from '@mui/material'

import { Colors } from '../../constants/appearance.constant'

interface IStyledInputProps {
  error?: boolean
  padding?: string
  disabled: boolean
}

const StyledInput = styled('input', { shouldForwardProp: (prop) => prop !== 'error' })(
  ({ error, padding, disabled }: IStyledInputProps) => ({
    width: '100%',
    height: '100%',
    padding: padding ? padding : '7px 16px',
    border: disabled ? 'none' : error ? `1px solid ${Colors.red}` : `1px solid ${Colors.blueOpacityMedium}`,
    borderRadius: '5px',
    backgroundColor: disabled ? Colors.greyLight : Colors.white,
    color: disabled ? Colors.blackLight : Colors.dark,
    transition: '0.2s',
    fontSize: '15px',
    ':focus': {
      outline: 'none',
      border: error ? `1px solid ${Colors.red}` : `1px solid ${Colors.bluePrimary}`,
      ':hover': {
        border: error ? `1px solid ${Colors.red}` : `1px solid ${Colors.bluePrimary}`
      }
    },
    '::placeholder': {
      opacity: '0.5'
    },
    ...(!disabled && {
      ':hover': {
        border: error ? `1px solid ${Colors.red}` : `1px solid ${Colors.blueOpacityHover}`
      }
    })
  })
)

const StyledTextError = styled('p')({
  color: Colors.red,
  fontSize: '13px',
  marginTop: '5px'
})

const StyledInputWrapper = styled('div')({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column'
})

interface IInputProps {
  value?: string | number | undefined
  handleChange: any
  placeholder?: string
  error?: boolean
  errorMessage?: string
  padding?: string
  disabled?: boolean
  other?: any
  sx?: Record<string, string | number>
  ref?: React.RefObject<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  type?: string
  maxLength?: number
}

const Input = ({
  value,
  handleChange,
  placeholder,
  error = false,
  errorMessage,
  padding,
  disabled = false,
  sx,
  other,
  ref,
  onFocus,
  type,
  maxLength = 50
}: IInputProps) => {
  return (
    <StyledInputWrapper>
      <StyledInput
        value={value}
        error={error}
        padding={padding}
        disabled={disabled}
        placeholder={placeholder}
        sx={sx}
        onChange={handleChange}
        ref={ref}
        onFocus={onFocus}
        type={type}
        maxLength={maxLength}
        {...other}
      />
      {error && <StyledTextError>{errorMessage}</StyledTextError>}
    </StyledInputWrapper>
  )
}

export default Input
