import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import API from '../../services/ajax.service'
import { API_ENDPOINTS } from '../../constants/api.constant'
import { toggleToast } from './ui.feature'
import { TOAST_MESSAGES } from '../../constants/ui.constant'

export interface ISubscription {
  id: string
  displayName: string
  licenses: number
  commitmentEndDate: string
  billingCycle: string
  cancellationAllowedUntilDate: string
  termDuration: string
}

export interface ISubscriptionCatalog {
  id: string | number
  msSubscriptionId?: string
  name: string
}

export const fetchSubscriptionsByTenantId = createAsyncThunk(
  API_ENDPOINTS.SUBSCRIPTIONS_FETCH,
  (id: string, { rejectWithValue }) =>
    API.getInstance()
      .get(`${API_ENDPOINTS.SUBSCRIPTIONS_FETCH}/${id}`)
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

export const fetchSubscriptionsCatalog = createAsyncThunk(
  API_ENDPOINTS.SUBSCRIPTIONS_CATALOG_FETCH,
  (id: string, { rejectWithValue }) =>
    API.getInstance()
      .get(`${API_ENDPOINTS.SUBSCRIPTIONS_CATALOG_FETCH}/${id}`)
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

interface IPostSubscriptionsFromCatalogData {
  userName: string
  userEmail: string
  tenantId: number
  msTenantId: string
  tenantName: string
  domain: string
  subscriptions: Record<string, string | number>[]
}

export const postSubscriptionsFromCatalog = createAsyncThunk(
  API_ENDPOINTS.SUBSCRIPTIONS_CATALOG_ADD,
  (postSubscriptionsFromCatalogData: IPostSubscriptionsFromCatalogData, { rejectWithValue, dispatch }) =>
    API.getInstance()
      .post(API_ENDPOINTS.SUBSCRIPTIONS_CATALOG_ADD, postSubscriptionsFromCatalogData)
      .then(() => {
        dispatch(toggleToast({ isToastOpen: true, success: true, title: TOAST_MESSAGES.getOfferSuccess }))
      })
      .catch((err) => {
        dispatch(toggleToast({ isToastOpen: true, success: false, title: err?.data?.message || TOAST_MESSAGES.failed }))
        return rejectWithValue(err)
      })
)

export interface INewSubscritionData {
  msSubscriptionId: string
  msTenantId: string
  quantity: number
}

export const postEditSubscriptionData = createAsyncThunk(
  'subscriptions/postEditSubscriptionData',
  (newSubscriptionData: INewSubscritionData, { dispatch, rejectWithValue }) =>
    API.getInstance()
      .post(API_ENDPOINTS.SUBSCRIPTIONS_EDIT, newSubscriptionData)
      .then((response) => {
        dispatch(toggleToast({ isToastOpen: true, success: true, title: TOAST_MESSAGES.success }))
        return response.data
      })
      .catch((err) => {
        dispatch(toggleToast({ isToastOpen: true, success: false, title: TOAST_MESSAGES.failed }))
        return rejectWithValue(err)
      })
)

interface IServiceState {
  subscriptions: ISubscription[] | []
  activeSubscription: ISubscription | null
  subscriptionsCatalog: ISubscriptionCatalog[]
  isFormSuccess: boolean
  loading: boolean
  error: boolean
}

const initialState: IServiceState = {
  subscriptions: [],
  activeSubscription: null,
  subscriptionsCatalog: [],
  isFormSuccess: false,
  loading: false,
  error: false
}

export const serviceSlice = createSlice({
  name: 'service',
  initialState,
  reducers: {
    setActiveSubscription: (state, action: PayloadAction<ISubscription | null>) => {
      state.activeSubscription = action.payload
    }
  },
  extraReducers: {
    [fetchSubscriptionsByTenantId.pending.type]: (state) => {
      state.subscriptions = []
      state.loading = true
      state.error = false
    },
    [fetchSubscriptionsByTenantId.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.subscriptions = payload.data
    },
    [fetchSubscriptionsByTenantId.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [postEditSubscriptionData.pending.type]: (state) => {
      state.loading = true
    },
    [postEditSubscriptionData.fulfilled.type]: (state) => {
      state.loading = false
      state.activeSubscription = null
    },
    [postEditSubscriptionData.rejected.type]: (state, { error }) => {
      state.loading = false
      state.error = error.message
    },
    [fetchSubscriptionsCatalog.pending.type]: (state) => {
      state.subscriptionsCatalog = []
      state.loading = true
      state.error = false
    },
    [fetchSubscriptionsCatalog.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.subscriptionsCatalog = payload.data
    },
    [fetchSubscriptionsCatalog.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [postSubscriptionsFromCatalog.pending.type]: (state) => {
      state.loading = true
      state.error = false
      state.isFormSuccess = false
    },
    [postSubscriptionsFromCatalog.fulfilled.type]: (state) => {
      state.loading = false
      state.isFormSuccess = true
    },
    [postSubscriptionsFromCatalog.rejected.type]: (state) => {
      state.loading = false
      state.error = true
      state.isFormSuccess = false
    }
  }
})

export const { setActiveSubscription } = serviceSlice.actions

export default serviceSlice.reducer
