import React from 'react'
import { styled } from '@mui/material'

const Wrapper = styled('div')`
  cursor: pointer;
`

const ArrowSelectIcon = () => (
  <Wrapper>
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path fill='#2E384D' fillRule='nonzero' d='m7 10 5 5 5-5z' />
      </g>
    </svg>
  </Wrapper>
)

export default ArrowSelectIcon
