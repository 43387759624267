import React from 'react'

const CatalogModalWarningIcon = () => (
  <svg width='178' height='115' viewBox='0 0 178 115' xmlns='http://www.w3.org/2000/svg'>
    <g fillRule='nonzero' fill='none'>
      <path
        d='M164.627 114.711H16.137a7.648 7.648 0 0 1-7.458-6.896L.316 7.198a6.266 6.266 0 0 1 1.612-4.865A6.257 6.257 0 0 1 6.63.302h148.482a7.656 7.656 0 0 1 7.465 6.896l8.364 100.603a6.273 6.273 0 0 1-1.61 4.872 6.264 6.264 0 0 1-4.704 2.038z'
        fill='#FFF'
      />
      <path
        d='M164.627 115H16.137a7.914 7.914 0 0 1-7.745-7.156L.028 7.234A6.626 6.626 0 0 1 1.732 2.16 6.542 6.542 0 0 1 6.63 0h148.482a7.914 7.914 0 0 1 7.753 7.198l8.356 100.603a6.546 6.546 0 0 1-1.686 5.078 6.537 6.537 0 0 1-4.908 2.12zM6.63.603A5.97 5.97 0 0 0 .596 7.198L8.96 107.794a7.353 7.353 0 0 0 7.191 6.63h148.476a5.97 5.97 0 0 0 6.026-6.58l-8.364-100.61a7.346 7.346 0 0 0-7.191-6.63H6.63z'
        fill='#B14FC5'
      />
      <path
        d='M155.112.317H6.63a6.254 6.254 0 0 0-4.697 2.026A6.265 6.265 0 0 0 .316 7.198h162.261a7.654 7.654 0 0 0-7.465-6.881z'
        fill='#B14FC5'
      />
      <path
        d='M10.103 3.765a1.347 1.347 0 0 1-1.359 1.49 1.655 1.655 0 0 1-1.61-1.49 1.354 1.354 0 0 1 1.358-1.49 1.64 1.64 0 0 1 1.611 1.49zM16.144 3.765a1.347 1.347 0 0 1-1.36 1.49 1.655 1.655 0 0 1-1.581-1.49 1.354 1.354 0 0 1 1.359-1.49 1.64 1.64 0 0 1 1.582 1.49zM22.185 3.765a1.347 1.347 0 0 1-1.36 1.49 1.655 1.655 0 0 1-1.61-1.49 1.354 1.354 0 0 1 1.359-1.49 1.64 1.64 0 0 1 1.61 1.49z'
        fill='#FFF'
      />
      <path
        d='M151.74 101.525H26.866a6.08 6.08 0 0 1-5.933-5.478l-5.869-70.135a4.984 4.984 0 0 1 5.034-5.478h124.895a6.08 6.08 0 0 1 5.933 5.478l5.832 70.171a4.976 4.976 0 0 1-5.02 5.442z'
        fill='#F0F0F0'
      />
      <path
        d='m113.107 32.685-38.69 46.627a11.085 11.085 0 0 0-1.952 10.693 11.07 11.07 0 0 0 8.122 7.215l62.782 13.582a11.062 11.062 0 0 0 10.798-3.674 11.085 11.085 0 0 0 1.823-11.268l-24.07-60.216a11.063 11.063 0 0 0-18.813-2.958zM26.982 39.22 13.505 75.578a7.001 7.001 0 0 0 1.04 6.674 6.987 6.987 0 0 0 6.194 2.683l39.36-3.865a6.99 6.99 0 0 0 5.759-4.24 7.001 7.001 0 0 0-.977-7.09L38.99 37.277a6.987 6.987 0 0 0-12.01 1.944z'
        fill='#DBDCDD'
      />
      <path
        d='M126.454 20.946 90.281 73.489a11.655 11.655 0 0 0-1.053 11.335 11.637 11.637 0 0 0 9.107 6.818l66.493 8.86a11.631 11.631 0 0 0 10.993-4.758 11.654 11.654 0 0 0 .974-11.947l-30.32-61.426a11.632 11.632 0 0 0-20.021-1.425z'
        fill='#B14FC5'
      />
      <path
        d='M99.91 86.95a7.35 7.35 0 0 1-5.623-4.082 6.483 6.483 0 0 1 .338-6.622l36.266-52.702a6.096 6.096 0 0 1 5.962-2.534 7.336 7.336 0 0 1 5.624 4.082l30.377 61.576a6.483 6.483 0 0 1-.338 6.622 6.089 6.089 0 0 1-5.962 2.541L99.91 86.95z'
        fill='#FFF'
      />
      <path
        d='m133.768 39.638 4.236.562a5.447 5.447 0 0 1 4.681 5.542l-.287 5.528c-.007.2-.029.4-.065.597L139.32 68.53a4.48 4.48 0 0 1-5.113 3.599 5.475 5.475 0 0 1-4.682-4.945l-1.215-17.217a4.157 4.157 0 0 1 0-.597l.28-5.535a4.471 4.471 0 0 1 1.65-3.259 4.463 4.463 0 0 1 3.528-.938zm-.338 37.112 1.15.151a4.879 4.879 0 0 1 4.193 4.96 4.003 4.003 0 0 1-4.639 3.779l-1.15-.16a4.872 4.872 0 0 1-4.193-4.951 3.996 3.996 0 0 1 4.639-3.779zM40.89 28.978l-9.932 37.695a7 7 0 0 0 1.668 6.56 6.986 6.986 0 0 0 6.437 2.077l38.525-7.521a6.992 6.992 0 0 0 5.289-4.724 7 7 0 0 0-1.557-6.922L52.756 25.948a6.993 6.993 0 0 0-11.866 3.03z'
        fill='#B14FC5'
      />
      <path
        d='M38.984 72.273a4.262 4.262 0 0 1-3.898-1.31 4.184 4.184 0 0 1-1.078-3.944l9.953-37.796a3.597 3.597 0 0 1 2.819-2.627 4.27 4.27 0 0 1 3.898 1.317l28.665 30.23a4.192 4.192 0 0 1 1.086 3.945 3.597 3.597 0 0 1-2.82 2.62l-38.625 7.565z'
        fill='#FFF'
      />
      <path
        d='m48.65 38.098 2.452-.482a3.25 3.25 0 0 1 3.653 2.353l.877 3.29c.034.12.06.243.08.367l1.56 10.357a2.685 2.685 0 0 1-2.157 3.103 3.236 3.236 0 0 1-3.539-2.001l-3.984-9.847a2.88 2.88 0 0 1-.122-.353l-.906-3.296a2.708 2.708 0 0 1 2.086-3.491zm6.94 21.795.668-.13a2.877 2.877 0 0 1 3.272 2.102 2.42 2.42 0 0 1-1.84 3.095l-.67.13a2.877 2.877 0 0 1-3.272-2.095 2.427 2.427 0 0 1 1.841-3.102z'
        fill='#B14FC5'
      />
    </g>
  </svg>
)

export default CatalogModalWarningIcon
