import React from 'react'
import { Box, Typography } from '@mui/material'
import { useMsal } from '@azure/msal-react'

import Button from '../components/button/button.component'

const LoginErrorPage = () => {
  const { instance } = useMsal()

  return (
    <Box sx={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
      <Box>
        <Typography sx={{ marginBottom: '20px' }}>Please login with the correct email</Typography>
        <Button
          onClick={() => {
            instance.logout()
          }}
        >
          Logout
        </Button>
      </Box>
    </Box>
  )
}

export default LoginErrorPage
