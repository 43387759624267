export const getEnvByUrl = () => {
  const base_url = window.location.host
  switch (true) {
    case CLIENT_URLS.staging.includes(base_url):
      return Enviroment.Staging
    case CLIENT_URLS.production.includes(base_url):
      return Enviroment.Production
    default:
      return Enviroment.Development
  }
}

export enum Enviroment {
  Development = 'development',
  Production = 'production',
  Staging = 'staging'
}

export const CLIENT_URLS = {
  [Enviroment.Development]: 'http://localhost:4200',
  [Enviroment.Staging]: 'https://witty-flower-080ad8e03.1.azurestaticapps.net',
  [Enviroment.Production]: 'https://portal.u-btech.com'
}

export const SERVER_URLS = {
  [Enviroment.Development]: 'http://localhost:3000/api',
  [Enviroment.Staging]: 'https://ubtech-potal-dev.azurewebsites.net/api',
  [Enviroment.Production]: 'https://ubtech-potal.azurewebsites.net/api'
}

export enum API_ENDPOINTS {
  LOGIN = '/login',
  OVERVIEW_FETCH = '/overview',
  SERVICES_SUBSCRIBE = '/services/subscribe',
  SUBSCRIPTIONS_FETCH = '/subscriptions',
  SUBSCRIPTIONS_EDIT = '/subscriptions/edit',
  SUBSCRIPTIONS_CATALOG_FETCH = '/subscriptions/catalog',
  SUBSCRIPTIONS_CATALOG_ADD = '/subscriptions/catalog/add',
  ADMIN_TENANTS_FETCH = '/admin/tenants',
  ADMIN_TENANTS_SYNC = '/admin/tenants/sync',
  ADMIN_USERS_FETCH = '/admin/users',
  ADMIN_USERS_INVITE = '/admin/users/invite',
  ADMIN_USERS_DELETE = '/admin/users/delete',
  ADMIN_USERS_EDIT = '/admin/users/edit',
  ADMIN_LOGS_ADD = '/admin/logs/add',
  ADMIN_LOGS_FETCH = '/admin/logs',
  ADMIN_CATALOG_MS_AND_SELECTED_FETCH = '/admin/subscriptions/catalog/ms-and-selected',
  ADMIN_CATALOG_MS_FETCH = '/admin/subscriptions/catalog/ms',
  ADMIN_CATALOG_FETCH = '/admin/subscriptions/catalog',
  ADMIN_CATALOG_DELETE = '/admin/subscriptions/catalog/delete',
  ADMIN_CATALOG_ADD = '/admin/subscriptions/catalog/add',
  ADMIN_SERVICES_FETCH = '/admin/services'
}

export const ERROR_CODE = {
  wrongInput: 400,
  unauthorized: 401,
  forbidden: 403,
  pageNotFound: 404,
  serverError: 500
}
