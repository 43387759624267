import React from 'react'
import { styled } from '@mui/material'

import { Colors } from '../../constants/appearance.constant'

interface IStyledModalProps {
  open: boolean
}

const StyledModal = styled('div')(({ open }: IStyledModalProps) => ({
  display: open ? 'grid' : 'none',
  placeItems: 'center',
  zIndex: '99999',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: Colors.blackOpacityMedium
}))

const StyledModalContent = styled('div')({
  padding: '20px',
  borderRadius: '4px',
  boxShadow: '0 8px 24px 0 rgba(0, 0, 0, 0.15)',
  backgroundColor: Colors.white
})

interface IModalProps {
  open: boolean
  onClose: () => void
  children: React.ReactNode | undefined
  sx?: Record<string, any>
}

const Modal = ({ open, onClose, children, sx }: IModalProps) => (
  <StyledModal onClick={onClose} sx={{ position: 'fixed' }} open={open}>
    <StyledModalContent
      onClick={(e) => {
        e.stopPropagation()
      }}
      sx={sx}
    >
      {children}
    </StyledModalContent>
  </StyledModal>
)

export default Modal
