// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./app/assets/fonts/Rubik-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./app/assets/fonts/Rubik-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./app/assets/fonts/Rubik-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./app/assets/fonts/Rubik-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: 'Rubik';\n  font-weight: 300;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n@font-face {\n  font-family: 'Rubik';\n  font-weight: 400;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n\n@font-face {\n  font-family: 'Rubik';\n  font-weight: 500;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n\n@font-face {\n  font-family: 'Rubik';\n  font-weight: 700;\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Rubik';\n  font-size: 15px;\n  font-weight: 400;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  overflow: hidden;\n}\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,4CAA4C;AAC9C;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,4CAA8C;AAChD;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,4CAA6C;AAC/C;;AAEA;EACE,oBAAoB;EACpB,gBAAgB;EAChB,4CAA2C;AAC7C;;AAEA;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,oBAAoB;EACpB,eAAe;EACf,gBAAgB;AAClB;;AAEA;;;EAGE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["@font-face {\n  font-family: 'Rubik';\n  font-weight: 300;\n  src: url(./app/assets/fonts/Rubik-Light.ttf);\n}\n\n@font-face {\n  font-family: 'Rubik';\n  font-weight: 400;\n  src: url(./app/assets/fonts/Rubik-Regular.ttf);\n}\n\n@font-face {\n  font-family: 'Rubik';\n  font-weight: 500;\n  src: url(./app/assets/fonts/Rubik-Medium.ttf);\n}\n\n@font-face {\n  font-family: 'Rubik';\n  font-weight: 700;\n  src: url(./app/assets/fonts/Rubik-Bold.ttf);\n}\n\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: 'Rubik';\n  font-size: 15px;\n  font-weight: 400;\n}\n\nhtml,\nbody,\n#root {\n  height: 100%;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
