import React from 'react'

const ArrowRightIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path fill='currentColor' fillRule='nonzero' d='M8.59 16.59 13.17 12 8.59 7.41 10 6l6 6-6 6z' />
    </g>
  </svg>
)

export default ArrowRightIcon
