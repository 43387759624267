import React from 'react'
import { Box } from '@mui/material'
import { Navigate, Route, Routes } from 'react-router-dom'

import { ROUTES } from '../../constants/routes.constant'
import OverviewPage from '../../pages/overview.page'
import Microsoft365Page from '../../pages/microsoft365.page'
import Toast from '../toast/toast.component'
import UsersManagmentPage from '../../pages/usersManagement.page'
import AddNewUserPage from '../../pages/addNewUser.page'
import PrivateRoute from '../privateRoute/privateRoute.component'
import { useAppSelector } from '../../hooks/redux.hook'
import LogsPage from '../../pages/logs.page'
import AddSubscriptionPage from '../../pages/addSubscriptions.page'
import CatalogPage from '../../pages/catalog.page'

const Main = () => {
  const { user } = useAppSelector((state) => state.user)
  return (
    <Box component='main' sx={{ height: '100%', flexGrow: 1, padding: '85px 25px 25px ' }}>
      <Routes>
        <Route path={ROUTES.OVERVIEW} element={<OverviewPage />} />
        <Route path={ROUTES.MICROSOFT} element={<Microsoft365Page />} />
        <Route path={ROUTES.MICROSOFT + ROUTES.ADD_LICENCES} element={<AddSubscriptionPage />} />
        <Route element={<PrivateRoute role={user?.role} />}>
          <Route path={ROUTES.ADMIN + ROUTES.USERS} element={<UsersManagmentPage />} />
          <Route path={ROUTES.ADMIN + ROUTES.USERS + ROUTES.ADD_USER} element={<AddNewUserPage />} />
          <Route path={ROUTES.ADMIN + ROUTES.LOGS} element={<LogsPage />} />
          <Route path={ROUTES.ADMIN + ROUTES.CATALOG} element={<CatalogPage />} />
          <Route path={ROUTES.ADMIN} element={<Navigate replace to={ROUTES.ADMIN + ROUTES.USERS} />} />
        </Route>
        <Route path='*' element={<Navigate replace to={ROUTES.OVERVIEW} />} />
      </Routes>
      <Toast />
    </Box>
  )
}

export default Main
