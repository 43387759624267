import { styled, Typography, Button as MuiButton } from '@mui/material'

import { Colors } from './appearance.constant'

export const StyledModalTitle = styled(Typography)({
  fontSize: '24px',
  fontWeight: '300',
  color: Colors.dark,
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  width: '90%'
})

export const StyledModalHeader = styled('div')({
  display: 'flex',
  justifyContent: 'space-between'
})

export const StyledModalFooter = styled('div')({
  display: 'flex',
  gap: '20px',
  justifyContent: 'end'
})

export const StyledCloseButton = styled('span')({
  color: Colors.dark,
  fontWeight: '300',
  fontSize: '28px',
  cursor: 'pointer',
  '&:hover': {
    opacity: '0.8'
  }
})

export const StyledInputLabel = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  color: Colors.grey,
  letterSpacing: '1px',
  'text-transform': 'uppercase',
  marginBottom: '5px'
})

interface IStyledMuiOutlinedButtonProps {
  active: boolean
}

export const StyledMuiOutlinedButton = styled(MuiButton, {
  shouldForwardProp: (prop) => prop !== 'active'
})(({ active }: IStyledMuiOutlinedButtonProps) => ({
  color: active ? Colors.bluePrimary : '',
  borderColor: active ? Colors.bluePrimary : '',
  backgroundColor: active ? Colors.blueOpacityLow : '',
  ':hover': {
    backgroundColor: Colors.blueOpacityLow
  }
}))

export const StyledGridContainer = styled('div')({
  height: '100%',
  display: 'grid',
  placeItems: 'center'
})
