import React from 'react'
import {
  Box,
  FormControl,
  InputBase,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  styled,
  Typography
} from '@mui/material'

import { Colors } from '../../constants/appearance.constant'
import ICONS from '../../constants/icons.constant'
import Input from '../input/input.component'
import { Roles, ROLE_TITLES } from '../../constants/global.constant'

const { UserIcon } = ICONS

const StyledPaper = styled(Paper)({
  width: '100%',
  border: '1px solid',
  borderColor: Colors.blueOpacityMedium,
  borderRadius: '8px',
  marginBottom: '32px'
})

const StyledPaperTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 300
})

const StyledInputLabel = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  color: Colors.grey,
  letterSpacing: '1px',
  'text-transform': 'uppercase',
  marginBottom: '5px'
})

const StyledPaperHeader = styled('div')({
  marginBottom: '30px'
})

const StyledPaperBody = styled('div')({})

// eslint-disable-next-line functional/no-mixed-type
interface IAddUserGeneralBoxProps {
  email: string
  role: Roles
  setEmail: React.Dispatch<React.SetStateAction<string>>
  onRoleChange: (event: SelectChangeEvent) => void
  isShowEmailError: (context: any) => boolean
  isActiveUser: boolean
}

const AddUserGeneralBox = ({
  role,
  email,
  setEmail,
  isActiveUser,
  onRoleChange,
  isShowEmailError
}: IAddUserGeneralBoxProps) => {
  return (
    <StyledPaper elevation={0} sx={{ padding: '20px 20px 30px 20px' }}>
      <StyledPaperHeader>
        <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
          <Box sx={{ marginRight: '8px', color: Colors.dark }}>
            <UserIcon />
          </Box>
          <StyledPaperTitle>General</StyledPaperTitle>
        </Box>
      </StyledPaperHeader>
      <StyledPaperBody>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: '14px' }}>
          <Box>
            <StyledInputLabel>User email</StyledInputLabel>
            <Box sx={{ width: '264px', height: '36px' }}>
              <Input
                disabled={isActiveUser}
                placeholder='Please type email'
                value={email}
                handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setEmail(e.target.value)
                }}
                error={isShowEmailError(email)}
                errorMessage={'Please enter a valid email'}
              />
            </Box>
          </Box>
          <Box>
            <StyledInputLabel>User role</StyledInputLabel>
            <Box sx={{ width: '264px' }}>
              <FormControl fullWidth variant='outlined'>
                <Select
                  displayEmpty
                  value={role}
                  onChange={onRoleChange}
                  input={<InputBase />}
                  renderValue={(role) =>
                    Object.keys(ROLE_TITLES).includes(role) ? ROLE_TITLES[role] : <>Select from the list</>
                  }
                  MenuProps={{ PaperProps: { style: { width: '260px', borderRadius: '8px', padding: '10px' } } }}
                >
                  {Object.keys(ROLE_TITLES).map((role, index) => (
                    <MenuItem key={index} value={role}>
                      {ROLE_TITLES[role as Roles]}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </StyledPaperBody>
    </StyledPaper>
  )
}

export default AddUserGeneralBox
