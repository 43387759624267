import React from 'react'
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputBase,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  styled,
  TextField,
  Typography
} from '@mui/material'
import _ from 'lodash'

import { Colors } from '../../constants/appearance.constant'
import ICONS from '../../constants/icons.constant'
import { SERVICES } from '../../constants/global.constant'
import { IUserTenant } from '../usersManagementForm/usersManagementForm.component'

const { AddIcon, TenantIcon } = ICONS

const StyledPaper = styled(Paper)({
  width: '100%',
  border: '1px solid',
  borderColor: Colors.blueOpacityMedium,
  borderRadius: '8px'
})

const StyledPaperTitle = styled(Typography)({
  fontSize: '18px',
  fontWeight: 300
})

const StyledInputLabel = styled(Typography)({
  fontSize: '12px',
  fontWeight: 500,
  color: Colors.grey,
  letterSpacing: '1px',
  'text-transform': 'uppercase',
  marginBottom: '5px'
})

const StyledAddIconWrapper = styled('div')({
  color: Colors.blackLight,
  cursor: 'pointer'
})

const StyledRemoveIconWrapper = styled('div')({
  color: Colors.blackLight,
  transform: 'rotate(45deg)',
  cursor: 'pointer'
})

const StyledPaperHeader = styled('div')({
  marginBottom: '30px'
})

const StyledPaperBody = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px'
})

const StyledTextError = styled('p')({
  color: Colors.red,
  fontSize: '13px',
  marginTop: '5px'
})

// eslint-disable-next-line functional/no-mixed-type
interface IAddNewUserTenantBoxProps {
  userTenantId: string
  relatedTenants: string[]
  userTenant: IUserTenant
  onPermissionChange: (value: string, userTenantId: string, serviceId: string) => void
  onCreateUserTenant: () => void
  onRemoveUserTenant: (userTenantId: string) => void
  onUserRelatedTenantsChange: (value: string, userTenantId: string) => void
  onServicesChange: (value: string, userTenantId: string, serviceId: string) => void
  isShowError: (context: any) => boolean
  userTenantLength: number
  relatedServices: string[]
  onTenantEmailSubscriprionChange: any
}

const AddNewUserTenantBox = ({
  userTenantId,
  userTenantLength,
  relatedTenants,
  userTenant,
  onPermissionChange,
  onCreateUserTenant,
  onRemoveUserTenant,
  onUserRelatedTenantsChange,
  onServicesChange,
  isShowError,
  relatedServices,
  onTenantEmailSubscriprionChange
}: IAddNewUserTenantBoxProps) => {
  const handleChangeRelatedTenant = (value: string) => {
    onUserRelatedTenantsChange(value, userTenantId)
  }

  const handlePermissionChange = (event: SelectChangeEvent, serviceId: string) => {
    const { value } = event.target
    onPermissionChange(value, userTenantId, serviceId)
  }

  const handleServicesChange = (event: SelectChangeEvent, serviceId: string) => {
    const { value } = event.target
    onServicesChange(value, userTenantId, serviceId)
  }

  const handleTenantEmailSubscriprionChange = (event: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    onTenantEmailSubscriprionChange(checked, userTenantId)
  }

  return (
    <StyledPaper elevation={0} sx={{ padding: '20px 20px 30px 20px' }}>
      <StyledPaperHeader>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ marginRight: '8px', color: Colors.dark }}>
              <TenantIcon />
            </Box>
            <StyledPaperTitle>Tenant</StyledPaperTitle>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
            {userTenantLength > 1 && (
              <StyledRemoveIconWrapper onClick={() => onRemoveUserTenant(userTenantId)}>
                <AddIcon />
              </StyledRemoveIconWrapper>
            )}
            <StyledAddIconWrapper onClick={onCreateUserTenant}>
              <AddIcon />
            </StyledAddIconWrapper>
          </Box>
        </Box>
      </StyledPaperHeader>
      <StyledPaperBody>
        <FormGroup>
          <FormControlLabel
            sx={{ marginBottom: '15px' }}
            control={<Checkbox />}
            label='Recieve a daily changes of tenant licenses'
            onChange={handleTenantEmailSubscriprionChange}
            checked={userTenant.emailSubscription}
          />
        </FormGroup>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '20px',
            paddingLeft: '2px'
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
            <Box>
              <StyledInputLabel>Related tenant</StyledInputLabel>
              <Box sx={{ width: '264px', height: '36px' }}>
                <Autocomplete
                  size='small'
                  value={userTenant.domain}
                  onChange={(event: React.SyntheticEvent<Element, Event>, value: string | null) => {
                    handleChangeRelatedTenant(value ? value : '')
                  }}
                  options={relatedTenants}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={'Select from the list'}
                      error={isShowError(userTenant.domain)}
                    />
                  )}
                />
                {isShowError(userTenant.domain) && <StyledTextError>Please select an option</StyledTextError>}
              </Box>
            </Box>
          </Box>
          <Box>
            {userTenant.services.map((service) => (
              <Box key={service._id} sx={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
                <Box>
                  <StyledInputLabel>Related service</StyledInputLabel>
                  <Box sx={{ width: '264px', height: '36px' }}>
                    <FormControl fullWidth variant='outlined'>
                      <Select
                        error={isShowError(userTenant.services)}
                        displayEmpty
                        value={service.name}
                        onChange={(event: SelectChangeEvent) => handleServicesChange(event, service._id)}
                        input={<InputBase placeholder='Select from the list' />}
                        renderValue={(selected) => SERVICES[selected as keyof typeof SERVICES]}
                        MenuProps={{ PaperProps: { style: { width: '260px' } }, elevation: 1 }}
                      >
                        {relatedServices.map((relatedService) => (
                          <MenuItem key={relatedService} value={relatedService}>
                            <Typography>{SERVICES[relatedService as keyof typeof SERVICES]}</Typography>
                          </MenuItem>
                        ))}
                      </Select>
                      {isShowError(userTenant.services) && <StyledTextError>Please select an option</StyledTextError>}
                    </FormControl>
                  </Box>
                </Box>
                <Box>
                  <StyledInputLabel>Permission</StyledInputLabel>
                  <FormControl sx={{ paddingTop: '8px' }}>
                    <RadioGroup
                      sx={{ gap: '10px' }}
                      row
                      value={service.permission}
                      onChange={(event: SelectChangeEvent) => handlePermissionChange(event, service._id)}
                    >
                      <FormControlLabel value='write' label='Editor' control={<Radio />} />
                      <FormControlLabel value='read' label='Viewer' control={<Radio />} />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </StyledPaperBody>
    </StyledPaper>
  )
}

export default AddNewUserTenantBox
