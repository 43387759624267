import { combineReducers } from '@reduxjs/toolkit'

import globalReducer from './features/global.feature'
import uiReducer from './features/ui.feature'
import adminReducer from './features/admin.feature'
import userReducer from './features/user.feature'
import serviceReducer from './features/service.feature'

const rootReducer = combineReducers({
  global: globalReducer, // example with async dispatch
  ui: uiReducer,
  admin: adminReducer,
  user: userReducer,
  service: serviceReducer
})

export default rootReducer
