import _ from 'lodash'

export const filterObject = (
  obj: Record<string, any>,
  callback: (key: string, val: any) => boolean
): Record<string, any> =>
  _.chain(obj)
    .entries()
    .filter(([key, val]) => callback(key, val))
    .fromPairs()
    .value()

export const getFormattedDate = (d: Date) =>
  _.chain([d.getDate(), d.getMonth() + 1, d.getFullYear()])
    .map((n) => (n < 10 ? `0${n}` : `${n}`))
    .join('/')
    .value()
