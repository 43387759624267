import React from 'react'
import { styled } from '@mui/material'

const Wrapper = styled('div')`
  cursor: pointer;
  display: flex;
  align-items: center;
  user-select: none;
`

const ArrowDownIcon = () => (
  <Wrapper>
    <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h24v24H0z' />
        <path fill='currentColor' fillRule='nonzero' d='m20 12-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8z' />
      </g>
    </svg>
  </Wrapper>
)

export default ArrowDownIcon
