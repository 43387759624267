import React from 'react'

const CheckIcon = () => (
  <svg width='24' height='24' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'>
    <g fill='none' fillRule='evenodd'>
      <path d='M0 0h24v24H0z' />
      <path fill='#2E5BFF' fillRule='nonzero' d='M9 16.2 4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4z' />
    </g>
  </svg>
)

export default CheckIcon
