import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface IModalConfig {
  title?: string
}

interface IToastConfig {
  title?: string
  isToastOpen: boolean
  success: boolean
}

interface IUiState {
  isModalOpen: boolean
  modalConfig?: IModalConfig
  toastConfig: IToastConfig
  isNavBarOpen: boolean
}

const initialState: IUiState = {
  isModalOpen: false,
  isNavBarOpen: true,
  toastConfig: {
    isToastOpen: false,
    title: '',
    success: false
  }
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleModal: (state, { payload }) => {
      state.isModalOpen = payload
    },
    toggleNavBar: (state) => {
      state.isNavBarOpen = !state.isNavBarOpen
    },
    toggleToast: (state, action: PayloadAction<IToastConfig>) => {
      state.toastConfig.isToastOpen = action.payload.isToastOpen
      state.toastConfig.title = action.payload.title
      state.toastConfig.success = action.payload.success
    }
  }
})

export const { toggleModal, toggleNavBar, toggleToast } = uiSlice.actions

export default uiSlice.reducer
