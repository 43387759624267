import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import _ from 'lodash'

import API from '../../services/ajax.service'
import { API_ENDPOINTS } from '../../constants/api.constant'
import { IService, ITenant, IUserTenant } from './user.feature'
import { toggleModal, toggleToast } from './ui.feature'
import { TOAST_MESSAGES } from '../../constants/ui.constant'
import { ROLE_TYPES } from '../../constants/global.constant'
import { ISubscriptionCatalog } from './service.feature'

// admin users thunks

export const fetchUsers = createAsyncThunk(API_ENDPOINTS.ADMIN_USERS_FETCH, (_, { rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.ADMIN_USERS_FETCH)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
)

export const deleteUser = createAsyncThunk(
  API_ENDPOINTS.ADMIN_USERS_DELETE,
  (userIds: number[], { rejectWithValue, dispatch }) =>
    API.getInstance()
      .post(API_ENDPOINTS.ADMIN_USERS_DELETE, userIds)
      .then((response) => {
        dispatch(toggleToast({ isToastOpen: true, success: true, title: TOAST_MESSAGES.deleteUserSuccess }))
        dispatch(toggleModal(false))
        return response.data
      })
      .catch((err) => {
        dispatch(toggleToast({ isToastOpen: true, success: false, title: TOAST_MESSAGES.failed }))
        dispatch(toggleModal(false))
        return rejectWithValue(err)
      })
)

export interface IUserData {
  id?: number
  email: string
  role: string
  tenants?: any
}

export const inviteUser = createAsyncThunk(
  API_ENDPOINTS.ADMIN_USERS_INVITE,
  (userData: IUserData, { dispatch, rejectWithValue }) =>
    API.getInstance()
      .post(API_ENDPOINTS.ADMIN_USERS_INVITE, userData)
      .then((response) => {
        dispatch(toggleToast({ isToastOpen: true, success: true, title: response.data.message }))
      })
      .catch((err) => {
        dispatch(toggleToast({ isToastOpen: true, success: false, title: TOAST_MESSAGES.failed }))
        return rejectWithValue(err)
      })
)

export const editUser = createAsyncThunk(
  API_ENDPOINTS.ADMIN_USERS_EDIT,
  (userData: IUserData, { dispatch, rejectWithValue }) =>
    API.getInstance()
      .post(API_ENDPOINTS.ADMIN_USERS_EDIT, userData)
      .then((response) => {
        dispatch(setEditedUser(response.data))
        dispatch(toggleToast({ isToastOpen: true, success: true, title: TOAST_MESSAGES.editUserSuccess }))
      })
      .catch((err) => {
        dispatch(toggleToast({ isToastOpen: true, success: false, title: TOAST_MESSAGES.failed }))
        return rejectWithValue(err)
      })
)

// admin tenants thunks

export const fetchAllTenants = createAsyncThunk(API_ENDPOINTS.ADMIN_TENANTS_FETCH, (_, { rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.ADMIN_TENANTS_FETCH)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
)

export const syncAllTenants = createAsyncThunk(API_ENDPOINTS.ADMIN_TENANTS_SYNC, (_, { dispatch, rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.ADMIN_TENANTS_SYNC)
    .then(() => {
      dispatch(toggleToast({ isToastOpen: true, success: true, title: TOAST_MESSAGES.syncTenantsSuccess }))
    })
    .catch((err) => {
      dispatch(toggleToast({ isToastOpen: true, success: false, title: TOAST_MESSAGES.failed }))
      return rejectWithValue(err)
    })
)

// admin services thunks

export const fetchAllServices = createAsyncThunk(API_ENDPOINTS.ADMIN_SERVICES_FETCH, (_, { rejectWithValue }) =>
  API.getInstance()
    .get(API_ENDPOINTS.ADMIN_SERVICES_FETCH)
    .then((response) => response.data)
    .catch((err) => rejectWithValue(err))
)

// admin logs thunks

export const fetchLogs = createAsyncThunk(
  API_ENDPOINTS.ADMIN_LOGS_FETCH,
  (fetchLogsData: Record<string, any>, { rejectWithValue }) =>
    API.getInstance()
      .post(API_ENDPOINTS.ADMIN_LOGS_FETCH, fetchLogsData)
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

interface ISendLogsData {
  email: string
  logs: ILog[]
}

export const sendLogs = createAsyncThunk(
  API_ENDPOINTS.ADMIN_LOGS_ADD,
  (ISendLogsData: ISendLogsData, { dispatch, rejectWithValue }) =>
    API.getInstance()
      .post(API_ENDPOINTS.ADMIN_LOGS_ADD, ISendLogsData)
      .then(() => {
        dispatch(toggleToast({ isToastOpen: true, success: true, title: TOAST_MESSAGES.sendLogsSuccess }))
      })
      .catch((err) => {
        dispatch(toggleToast({ isToastOpen: true, success: false, title: err?.data?.message || TOAST_MESSAGES.failed }))
        return rejectWithValue(err)
      })
)

// admin subscriptions catalog thunks

export const fetchMsAndSelectedCatalogs = createAsyncThunk(
  API_ENDPOINTS.ADMIN_CATALOG_MS_AND_SELECTED_FETCH,
  (_, { rejectWithValue }) =>
    API.getInstance()
      .get(API_ENDPOINTS.ADMIN_CATALOG_MS_AND_SELECTED_FETCH)
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

export const fetchAllSubscriptionsCatalog = createAsyncThunk(
  API_ENDPOINTS.ADMIN_CATALOG_MS_FETCH,
  (_, { rejectWithValue }) =>
    API.getInstance()
      .get(API_ENDPOINTS.ADMIN_CATALOG_MS_FETCH)
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

export const fetchSelectedSubscriptionsCatalog = createAsyncThunk(
  API_ENDPOINTS.ADMIN_CATALOG_FETCH,
  (_, { rejectWithValue }) =>
    API.getInstance()
      .get(API_ENDPOINTS.ADMIN_CATALOG_FETCH)
      .then((response) => response.data)
      .catch((err) => rejectWithValue(err))
)

export const deleteFromAdminSelectedSubscriptionsCatalog = createAsyncThunk(
  API_ENDPOINTS.ADMIN_CATALOG_DELETE,
  (selectedSubscriptionsCatalogIds: number[], { rejectWithValue, dispatch }) =>
    API.getInstance()
      .post(API_ENDPOINTS.ADMIN_CATALOG_DELETE, selectedSubscriptionsCatalogIds)
      .then((response) => {
        dispatch(
          toggleToast({ isToastOpen: true, success: true, title: TOAST_MESSAGES.deleteSubsFromSelectedCatalogSuccess })
        )
        dispatch(toggleModal(false))
        return response.data
      })
      .catch((err) => {
        dispatch(toggleToast({ isToastOpen: true, success: false, title: TOAST_MESSAGES.failed }))
        dispatch(toggleModal(false))
        return rejectWithValue(err)
      })
)

export const addToAdminSelectedSubscriptionsCatalog = createAsyncThunk(
  API_ENDPOINTS.ADMIN_CATALOG_ADD,
  (selectedSubscriptionsCatalog: ISubscriptionCatalog[], { rejectWithValue, dispatch }) =>
    API.getInstance()
      .post(API_ENDPOINTS.ADMIN_CATALOG_ADD, selectedSubscriptionsCatalog)
      .then((response) => {
        dispatch(
          toggleToast({ isToastOpen: true, success: true, title: TOAST_MESSAGES.addingSubsFromSelectedCatalogSuccess })
        )
        dispatch(setIsCatalogModalOpen(false))
        return response.data
      })
      .catch((err) => {
        dispatch(toggleToast({ isToastOpen: true, success: false, title: TOAST_MESSAGES.failed }))
        dispatch(setIsCatalogModalOpen(false))
        return rejectWithValue(err)
      })
)

interface ILog {
  id: number
  userId: number
  msTenantId: string
  subscriptionName: string
  msSubscriptionId: string
  prevQuantity: number
  newQuantity: number
  delta: string
  createdAt: string
  changeBy: string
  userName: string
  tenantName: string
}

interface IAdminState {
  loading: boolean
  error: boolean
  users: IUserTenant[]
  services: IService[]
  tenants: ITenant[]
  activeUser: IUserTenant | null
  logs: ILog[]
  isCatalogModalOpen: boolean
  adminAllSubscriptionsCatalog: ISubscriptionCatalog[]
  adminSelectedSubscriptionsCatalog: ISubscriptionCatalog[]
  catalogModalError: boolean
  isUserCreationSuccess: boolean
}

const initialState: IAdminState = {
  loading: false,
  error: false,
  users: [],
  tenants: [],
  services: [],
  activeUser: null,
  isCatalogModalOpen: false,
  logs: [],
  adminAllSubscriptionsCatalog: [],
  adminSelectedSubscriptionsCatalog: [],
  catalogModalError: false,
  isUserCreationSuccess: false
}

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setActiveUser: (state, action) => {
      state.activeUser = action.payload
    },
    setEditedUser: (state, { payload }) => {
      state.users = state.users.map((user) =>
        user?.id === payload?.data?.id
          ? { ...payload?.data, role: payload?.data?.role === 'sales_rep' ? ROLE_TYPES.salesRep : payload?.data?.role }
          : user
      )
    },
    setIsCatalogModalOpen: (state, { payload }) => {
      state.isCatalogModalOpen = payload
    },
    setIsUserCreationSuccess: (state, { payload }) => {
      state.isUserCreationSuccess = payload
    }
  },
  extraReducers: {
    [fetchUsers.pending.type]: (state) => {
      state.loading = true
      state.error = false
      state.users = []
    },
    [fetchUsers.fulfilled.type]: (state, { payload }: PayloadAction<{ data: IUserTenant[] }>) => {
      state.loading = false
      state.users = payload.data.map((user) => ({
        ...user,
        role: user.role === 'sales_rep' ? ROLE_TYPES.salesRep : user.role
      }))
    },
    [fetchUsers.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [deleteUser.pending.type]: (state) => {
      state.loading = true
      state.error = false
    },
    [deleteUser.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.users = state?.users?.filter((user) => !payload?.data?.includes(user.id))
    },
    [deleteUser.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [fetchAllTenants.pending.type]: (state) => {
      state.loading = true
      state.error = false
      state.tenants = []
    },
    [fetchAllTenants.fulfilled.type]: (state, { payload }: PayloadAction<{ data: ITenant[] }>) => {
      state.loading = false
      state.tenants = payload.data
    },
    [fetchAllTenants.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [fetchAllServices.pending.type]: (state) => {
      state.loading = true
      state.error = false
      state.services = []
    },
    [fetchAllServices.fulfilled.type]: (state, { payload }: PayloadAction<{ data: IService[] }>) => {
      state.loading = false
      state.services = payload.data
    },
    [fetchAllServices.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [syncAllTenants.pending.type]: (state) => {
      state.loading = true
      state.error = false
    },
    [syncAllTenants.fulfilled.type]: (state) => {
      state.loading = false
    },
    [syncAllTenants.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [inviteUser.pending.type]: (state) => {
      state.loading = true
      state.error = false
    },
    [inviteUser.fulfilled.type]: (state) => {
      state.isUserCreationSuccess = true
      state.loading = false
    },
    [inviteUser.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [editUser.pending.type]: (state) => {
      state.loading = true
      state.error = false
    },
    [editUser.fulfilled.type]: (state) => {
      state.loading = false
    },
    [editUser.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [sendLogs.pending.type]: (state) => {
      state.loading = true
      state.error = false
    },
    [sendLogs.fulfilled.type]: (state) => {
      state.loading = false
    },
    [sendLogs.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [fetchLogs.pending.type]: (state) => {
      state.logs = []
      state.loading = true
      state.error = false
    },
    [fetchLogs.fulfilled.type]: (state, { payload }: PayloadAction<{ data: ILog[] }>) => {
      state.loading = false
      state.logs = payload.data
    },
    [fetchLogs.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [fetchAllSubscriptionsCatalog.pending.type]: (state) => {
      state.adminAllSubscriptionsCatalog = []
      state.loading = true
      state.catalogModalError = false
    },
    [fetchAllSubscriptionsCatalog.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ data: ISubscriptionCatalog[] }>
    ) => {
      state.loading = false
      state.adminAllSubscriptionsCatalog = payload.data
    },
    [fetchAllSubscriptionsCatalog.rejected.type]: (state) => {
      state.loading = false
      state.catalogModalError = true
    },
    [fetchSelectedSubscriptionsCatalog.pending.type]: (state) => {
      state.adminSelectedSubscriptionsCatalog = []
      state.loading = true
      state.error = false
    },
    [fetchSelectedSubscriptionsCatalog.fulfilled.type]: (
      state,
      { payload }: PayloadAction<{ data: ISubscriptionCatalog[] }>
    ) => {
      state.loading = false
      state.adminSelectedSubscriptionsCatalog = payload.data
    },
    [fetchSelectedSubscriptionsCatalog.rejected.type]: (state) => {
      state.loading = false
      state.error = true
    },
    [deleteFromAdminSelectedSubscriptionsCatalog.pending.type]: (state) => {
      state.loading = true
      state.error = false
    },
    [deleteFromAdminSelectedSubscriptionsCatalog.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.adminSelectedSubscriptionsCatalog = _.filter(
        state?.adminSelectedSubscriptionsCatalog,
        (sub) => !_.includes(payload?.data, sub.id)
      )
    },
    [deleteFromAdminSelectedSubscriptionsCatalog.rejected.type]: (state) => {
      state.loading = false
    },
    [addToAdminSelectedSubscriptionsCatalog.pending.type]: (state) => {
      state.loading = true
      state.error = false
    },
    [addToAdminSelectedSubscriptionsCatalog.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.adminSelectedSubscriptionsCatalog = state.adminSelectedSubscriptionsCatalog.concat(payload.data)
    },
    [addToAdminSelectedSubscriptionsCatalog.rejected.type]: (state) => {
      state.loading = false
    },
    [fetchMsAndSelectedCatalogs.pending.type]: (state) => {
      state.adminAllSubscriptionsCatalog = []
      state.adminSelectedSubscriptionsCatalog = []
      state.loading = true
      state.error = false
    },
    [fetchMsAndSelectedCatalogs.fulfilled.type]: (state, { payload }) => {
      state.loading = false
      state.adminAllSubscriptionsCatalog = payload?.data?.msCatalog
      state.adminSelectedSubscriptionsCatalog = payload?.data?.selectedCatalog
    },
    [fetchMsAndSelectedCatalogs.rejected.type]: (state) => {
      state.loading = false
    }
  }
})

export const { setActiveUser, setEditedUser, setIsCatalogModalOpen, setIsUserCreationSuccess } = adminSlice.actions

export default adminSlice.reducer
