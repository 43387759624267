import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Typography } from '@mui/material'
import _ from 'lodash'

import ICONS from '../constants/icons.constant'
import Button from '../components/button/button.component'
import Table from '../components/table/table.component'
import { useAppDispatch, useAppSelector } from '../hooks/redux.hook'
import { CellType } from '../constants/global.constant'
import Search from '../components/search/search.component'
import { fetchSubscriptionsCatalog, postSubscriptionsFromCatalog } from '../state/features/service.feature'
import { filterObject } from '../utils/global.util'
import { StyledGridContainer } from '../constants/styles.constant'

const { ArrowRightIcon } = ICONS

const columns = [
  { displayName: 'License title', key: 'name', cellType: CellType.text, width: '40%' },
  { displayName: 'Select quantity', key: 'quantity', cellType: CellType.input, width: '60%' }
]

const AddSubscriptionPage = () => {
  const dispatch = useAppDispatch()
  const { activeTenant, user } = useAppSelector((state) => state.user)
  const { subscriptionsCatalog, isFormSuccess, loading, error } = useAppSelector((state) => state.service)

  const [inputValue, setInputValue] = useState<string>('')
  const [countValues, setCountValues] = useState<Record<string, number>>({})

  const resetCountValuesState = () => {
    setCountValues(subscriptionsCatalog?.reduce((sum, current) => ({ ...sum, [current.id]: 0 }), {}))
  }

  useEffect(() => {
    activeTenant && dispatch(fetchSubscriptionsCatalog(activeTenant.msTenantId))
  }, [activeTenant])

  useEffect(() => {
    resetCountValuesState()
  }, [subscriptionsCatalog])

  useEffect(() => {
    isFormSuccess && resetCountValuesState()
  }, [isFormSuccess])

  const shouldRenderGetOffer = () => _.find(countValues, (curr) => (curr > 0 && curr ? true : false))

  const handleQuantityChange = (id: string, value: string) => {
    !_.isNaN(Number(value)) && setCountValues({ ...countValues, [id]: Number(value.slice(0, 5)) })
  }

  const handleSubmit = () => {
    const filteredSubscriptions = filterObject(countValues, (_, val) => val !== 0)
    const parsedSubscriptions = _.chain(filteredSubscriptions)
      .keys()
      .map((key) => {
        const subscriptionCatalog = _.find(subscriptionsCatalog, (subscription) => subscription.id === +key)
        return {
          ...subscriptionCatalog,
          quantity: countValues[key]
        }
      })
      .value()
    activeTenant &&
      user &&
      dispatch(
        postSubscriptionsFromCatalog({
          userName: user?.name,
          userEmail: user?.email,
          tenantId: activeTenant?.id,
          msTenantId: activeTenant?.msTenantId,
          tenantName: activeTenant?.name,
          domain: activeTenant?.domain,
          subscriptions: parsedSubscriptions
        })
      )
  }

  const filteredItems = subscriptionsCatalog?.filter((subscription) =>
    subscription.name.toLowerCase().includes(inputValue.toLowerCase())
  )

  if (error && _.isEmpty(subscriptionsCatalog))
    return (
      <StyledGridContainer>
        <Typography>Something went wrong</Typography>
      </StyledGridContainer>
    )

  if (_.isEmpty(subscriptionsCatalog))
    return (
      <StyledGridContainer>
        {loading ? <CircularProgress /> : <Typography>No licenses to choose</Typography>}
      </StyledGridContainer>
    )

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '20px 0' }}>
        <Box sx={{ width: '300px' }}>
          <Search
            value={inputValue}
            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setInputValue(e.target.value)
            }}
          />
        </Box>
        <Box sx={{ width: '125px', display: shouldRenderGetOffer() ? 'block' : 'none' }}>
          <Button loading={loading} onClick={handleSubmit}>
            Get offer
            <ArrowRightIcon />
          </Button>
        </Box>
      </Box>
      {!_.isEmpty(countValues) && (
        <Box sx={{ height: '90%', overflow: 'auto' }}>
          <Table
            columns={columns}
            items={filteredItems}
            countValues={countValues}
            onChangeHandle={handleQuantityChange}
          />
        </Box>
      )}
    </>
  )
}

export default AddSubscriptionPage
