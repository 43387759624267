import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

import { ROLE_TYPES } from '../../constants/global.constant'
import { ROUTES } from '../../constants/routes.constant'

interface IPrivateRoute {
  children?: JSX.Element
  role?: string
}

const PrivateRoute = ({ children, role }: IPrivateRoute) => {
  if (role === ROLE_TYPES.admin) {
    return children ? children : <Outlet />
  }
  return <Navigate to={{ pathname: ROUTES.OVERVIEW }} />
}

export default PrivateRoute
